import React from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import StrategyNotesHeader from './StrategyNotesHeader'
import StrategyNotes from './StrategyNotes'
const StrategyNotesSection = ({
  newNoteContent,
  setNewNoteContent,
  handleAddNote,
  handleClear,
  isCreatingNote,
  setIsCreatingNote,
  isEditMode,
  notes,
  setNotes,
}) => {
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          mb: 1,
          bgcolor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)',
        }}
      >
        <StrategyNotesHeader
          newNoteContent={newNoteContent}
          setNewNoteContent={setNewNoteContent}
          handleAddNote={handleAddNote}
          onClear={handleClear}
          isCreatingNote={isCreatingNote}
          setIsCreatingNote={setIsCreatingNote}
        />
      </Box>
      <Grid container>
        <Grid item xs={12} pl={2.5}>
          <StrategyNotes
            isEditMode={isEditMode}
            newNoteContent={newNoteContent}
            setNewNoteContent={setNewNoteContent}
            notes={notes}
            setNotes={setNotes}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default StrategyNotesSection

import { v4 as uuidv4 } from 'uuid'
import { productFieldsConfig, searchProducts, socialProducts } from './editFieldConfig'

export const generateServiceFields = (productName, product, instanceId) => {
  const fieldsConfig = productFieldsConfig[productName]

  if (!fieldsConfig) {
    return [] // No specific fields for this product
  }

  // Generate fields with values from the product or empty strings as default
  return fieldsConfig.map(({ fieldName, label }) => ({
    fieldName,
    label, // Include label
    value: product[fieldName] || '',
    instanceId, // Attach instanceId for each field
  }))
}

export const addServiceToStrategy = (strategyState, productName, product) => {
  const instanceId = uuidv4() // Generate an instance ID
  const serviceFields = generateServiceFields(productName, product, instanceId)
  const service = {
    instanceId,
    productName,
    payer: product.payer,
    accountUsed: product.accountUsed,
    mediaSpend: product.mediaSpend,
    price: product.price,
    specificFields: serviceFields,
  }

  // Based on the product category, add the service to the appropriate section
  if (searchProducts.includes(productName)) {
    strategyState.paidSearch.services.push(service)
    strategyState.paidSearch.active = true
  } else if (socialProducts.includes(productName)) {
    strategyState.paidSocial.services.push(service)
    strategyState.paidSocial.active = true
  } else if (productName.includes('SEO')) {
    strategyState.seo.services.push(service)
    strategyState.seo.active = true
  } else if (productName.includes('Display')) {
    strategyState.display.services.push(service)
    strategyState.display.active = true
  } else if (productName.includes('Website')) {
    strategyState.website.services.push(service)
    strategyState.website.active = true
  }

  return strategyState
}

export const determineStrategyFields = product => {
  let strategyState = {
    paidSearch: { active: false, services: [] },
    paidSocial: { active: false, services: [] },
    seo: { active: false, services: [] },
    display: { active: false, services: [] },
    website: { active: false, services: [] },
    addtlInfo: { active: true },
    integration: { active: true },
  }

  // Use helper to add the product-specific service fields
  strategyState = addServiceToStrategy(strategyState, product.productName, product)

  return strategyState
}

export const determineStrategyState = (products = []) => {
  const initialState = {
    paidSearch: { active: false, services: [] },
    paidSocial: { active: false, services: [] },
    seo: { active: false, services: [] },
    smm: { active: false, services: [] },
    website: { active: false, services: [] },
    display: { active: false, services: [] },
    creative: { active: false, services: [] },
    addtlInfo: { active: true }, // Always active
    integration: { active: true }, // Always active
    searchCampaign: { active: false },
    socialCampaign: { active: false },
    socialAds: { active: false },
  }

  // If there are no products, return the initial state
  if (!products || products.length === 0) return initialState

  return products.reduce((acc, product) => {
    const productStrategy = determineStrategyFields(product)
    // console.log('PRODUCT STRATEGY: ', productStrategy)

    // Ensure that all product strategies include active and services
    const hasValidService = service => service.specificFields && service.specificFields.length > 0

    return {
      ...acc,
      // Ensure paidSearch is properly initialized and merged
      paidSearch: {
        active: acc.paidSearch.active || productStrategy.paidSearch?.active,
        services: productStrategy.paidSearch?.active
          ? [...acc.paidSearch.services, ...productStrategy.paidSearch.services.filter(hasValidService)]
          : acc.paidSearch.services,
      },
      // Ensure paidSocial is properly initialized and merged
      paidSocial: {
        active: acc.paidSocial.active || productStrategy.paidSocial?.active,
        services: productStrategy.paidSocial?.active
          ? [...acc.paidSocial.services, ...productStrategy.paidSocial.services.filter(hasValidService)]
          : acc.paidSocial.services,
      },
      // Ensure seo is properly initialized and merged
      seo: {
        active: acc.seo.active || productStrategy.seo?.active,
        services: productStrategy.seo?.active
          ? [...acc.seo.services, ...productStrategy.seo.services.filter(hasValidService)]
          : acc.seo.services,
      },
      // Ensure display is properly initialized and merged
      display: {
        active: acc.display.active || productStrategy.display?.active,
        services: productStrategy.display?.active
          ? [...acc.display.services, ...productStrategy.display.services.filter(hasValidService)]
          : acc.display.services,
      },
      // Continue for other fields...
      smm: {
        active: acc.smm.active || productStrategy.smm?.active,
        services: productStrategy.smm?.active
          ? [...acc.smm.services, ...productStrategy.smm.services.filter(hasValidService)]
          : acc.smm.services,
      },
      addtlInfo: { active: acc.addtlInfo.active || productStrategy.addtlInfo?.active },
      integration: { active: acc.integration.active || productStrategy.integration?.active },
      searchCampaign: { active: acc.searchCampaign.active || productStrategy.searchCampaign?.active },
      socialCampaign: { active: acc.socialCampaign.active || productStrategy.socialCampaign?.active },
      socialAds: { active: acc.socialAds.active || productStrategy.socialAds?.active },
    }
  }, initialState)
}

// import { v4 as uuidv4 } from 'uuid'

// export const determineStrategyFields = product => {
//   const { productName, payer, accountUsed, mediaSpend, price } = product
//   let strategyState = {
//     integration: { active: true },
//     addtlInfo: { active: true },
//     // Product-specific fields
//     paidSearch: { active: false, services: [] },
//     paidSocial: { active: false, services: [] },
//     seo: { active: false, services: [] },
//     display: { active: false, services: [] },
//     smm: { active: false, services: [] },
//     website: { active: false },
//     display: { active: false, services: [] },
//     creative: { active: false },
//     searchCampaign: { active: false },
//     socialCampaign: { active: false },
//     socialAds: { active: false },
//     services: [],
//   }
//   switch (productName) {
//     case 'Meta Advertising':
//       strategyState.paidSocial.active = true
//       strategyState.paidSocial.services.push({
//         instanceId: uuidv4(),
//         productName,
//         payer,
//         accountUsed,
//         mediaSpend,
//         price,
//       })
//       break
//     case 'Linkedin Advertising':
//       strategyState.paidSocial.active = true
//       strategyState.services.push({
//         instanceId: uuidv4(),
//         productName,
//         payer,
//         accountUsed,
//         mediaSpend,
//         price,
//       })
//       break
//     case 'Spotify Advertising':
//       strategyState.paidSocial.active = true
//       strategyState.services.push({
//         instanceId: uuidv4(),
//         productName,
//         payer,
//         accountUsed,
//         mediaSpend,
//         price,
//       })
//       break
//     case 'TikTok Advertising':
//       strategyState.paidSocial.active = true
//       strategyState.services.push({
//         instanceId: uuidv4(),
//         productName,
//         payer,
//         accountUsed,
//         mediaSpend,
//         price,
//       })
//       break
//     case 'SnapChat Advertising':
//       strategyState.paidSocial.active = true
//       strategyState.services.push({
//         instanceId: uuidv4(),
//         productName,
//         payer,
//         accountUsed,
//         mediaSpend,
//         price,
//       })
//       break
//     case 'Social Media Advertising Management Fee':
//       strategyState.paidSocial.active = true
//       strategyState.services.push({
//         instanceId: uuidv4(),
//         productName,
//         payer,
//         accountUsed,
//         mediaSpend,
//         price,
//       })
//       break
//     case 'Linkedin Advertising':
//       strategyState.paidSocial.active = true
//       strategyState.services.push({
//         instanceId: uuidv4(),
//         productName,
//         payer,
//         accountUsed,
//         mediaSpend,
//         price,
//       })
//       break
//     case 'Paid Social Management Fee':
//       strategyState.paidSocial.active = true
//       strategyState.services.push({
//         instanceId: uuidv4(),
//         productName,
//         payer,
//         accountUsed,
//         mediaSpend,
//         price,
//       })
//       break
//     // Paid Search
//     case 'Paid Search Advertising':
//       strategyState.paidSearch.active = true
//       strategyState.services.push({
//         instanceId: uuidv4(),
//         productName,
//         payer,
//         accountUsed,
//         mediaSpend,
//         price,
//       })
//       break
//     case 'Paid Search Management Fee':
//       strategyState.paidSearch.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break
//     case 'Paid Search - Media Spend':
//       strategyState.paidSearch.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break
//     case 'Microsoft Advertising':
//       strategyState.paidSearch.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break

//     case 'Amazon Advertising':
//       strategyState.paidSearch.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       strategyState.searchCampaign.active = true
//       break
//     case 'Agency Retainer - Digital':
//     case 'Management Fee / Retainer':
//       strategyState.paidSearch.active = true
//       strategyState.paidSocial.active = true
//       strategyState.display.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break
//     case 'Website Hosting':
//     case 'Website Build - Page Creation':
//     case 'Website Hosting + 30 Minutes of Maintenance':
//     case 'Website - Add On':
//     case 'Website Build - Additional Page Creation':
//     case 'Website Revisions - Additional':
//     case 'Standard Website Build':
//     case 'Website Revisions':
//     case 'Website Hosting':
//     case 'Premium Website Build':
//     case 'Monthly Website Plan':
//     case 'Website Maintenance Retainer':
//     case 'Website Transfer Fee':
//     case 'Website Hosting + 60 Minutes of Maintenance':
//     case 'Agency Retainer - Website':
//     case 'E-Commerce Website Build':
//     case 'Monthly Hosting Plan':
//       strategyState.website.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break
//     case 'Programmatic Display Advertising':
//       strategyState.display.active = true
//     case 'Programmatic Display Management Fee':
//       strategyState.display.active = true
//     case 'Programmatic Video Advertising':
//       strategyState.display.active = true
//     case 'Programmatic Display Advertising':
//       strategyState.display.active = true
//     case 'Programmatic Audio Advertising':
//       strategyState.display.active = true
//     case 'Google Display Advertising':
//       strategyState.display.active = true
//     case 'Display Management Fee':
//       strategyState.display.active = true
//     case 'Geofencing Advertising Management Fee':
//       strategyState.display.active = true
//     case 'Youtube Advertising':
//       strategyState.display.active = true
//     case 'Retargeting Advertising Management Fee':
//       strategyState.display.active = true
//     case 'Audio Management Fee':
//       strategyState.display.active = true
//       break
//     case 'Design Materials':
//     case 'Graphic Design - Logo Creation':
//     case 'Graphic Design - Additional Logo Revisions':
//     case 'Graphic Design - Revisions':
//     case 'Graphic Design - General':
//     case 'Photo Editing':
//     case 'Photo Shoot':
//     case 'Drone Video - Add On':
//     case 'Lifestyle Video Shoot':
//     case 'Video Editing':
//     case 'Video Management Fee':
//     case 'Video Shoot':
//     case 'Agency Retainer - Creative':
//     case 'Business Systems Kit':
//     case 'Branding Guide':
//     case 'Talent/Models':
//     case 'Property Naming':
//     case 'Custom API Integration':
//     case 'Marketing Collateral':
//     case 'Content Migration':
//     case 'Creative Services - Hourly':
//     case 'Product Photography Staging Equipment':
//     case 'Full Brand Build':
//     case 'Drone Shoot - Add On':
//     case 'E-Commerce Integration':
//     case 'Travel, Lodging, Misc.':
//     case 'Landing Page':
//     case 'Server Migration':
//       strategyState.creative.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break
//     case 'SEO - Custom Tier':
//     case 'SEO - Tier 1':
//     case 'Monthly SEO Plan - Tier 1':
//     case 'SEO - Additional Location':
//     case 'Listing Sync Pro':
//       strategyState.seo.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break
//     case 'SEO - Tier 2':
//     case 'SEO - Tier 3':
//     case 'SEO - Tier 4':
//       strategyState.seo.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break
//     case 'Social Media Management':
//     case 'Social Content Calendar':
//     case 'Social Profile Creation':
//       strategyState.smm.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break
//     case 'Consulting':
//       strategyState.seo.active = true
//       strategyState.smm.active = true
//       strategyState.creative.active = true
//       strategyState.website.active = true
//       strategyState.paidSearch.active = true
//       strategyState.paidSocial.active = true
//       strategyState.display.active = true
//       strategyState.integration.active = true
//       strategyState.addtlInfo.active = true
//       break
//     default:
//       break
//   }
//   // / Only add the product's details to the relevant service if the state is active for that product
//   return strategyState
// }
// export const determineStrategyState = (products = []) => {
//   const initialState = {
//     paidSearch: { active: false, services: [] },
//     paidSocial: { active: false, services: [] },
//     seo: { active: false, services: [] },
//     smm: { active: false, services: [] },
//     website: { active: false, services: [] },
//     display: { active: false, services: [] },
//     creative: { active: false, services: [] },
//     addtlInfo: { active: true },
//     integration: { active: true },
//     searchCampaign: { active: false },
//     socialCampaign: { active: false },
//     socialAds: { active: false },
//   }

//   if (!products || products.length === 0) return initialState

//   return products.reduce((acc, product) => {
//     const productStrategy = determineStrategyFields(product)
//     console.log('PRODUCT STRATEGY: ', productStrategy)
//     // Only add a service if it has specificFields or some valid values
//     const hasValidService = service => {
//       return service.specificFields && service.specificFields.length > 0
//     }

//     return {
//       ...acc,
//       // Merge paidSearch fields
//       paidSearch: {
//         active: acc.paidSearch.active || productStrategy.paidSearch.active,
//         services: productStrategy.paidSearch.active
//           ? [
//               ...acc.paidSearch.services,
//               ...productStrategy.services.filter(hasValidService), // Only add if it has valid specific fields
//             ]
//           : acc.paidSearch.services,
//         specificFields: productStrategy.paidSearch.active
//           ? [...(acc.paidSearch.specificFields || []), ...(productStrategy.paidSearch.specificFields || [])]
//           : acc.paidSearch.specificFields,
//       },
//       // Merge paidSocial fields
//       paidSocial: {
//         active: acc.paidSocial.active || productStrategy.paidSocial.active,
//         services: productStrategy.paidSocial.active
//           ? [
//               ...acc.paidSocial.services,
//               ...productStrategy.services.filter(hasValidService), // Only add if it has valid specific fields
//             ]
//           : acc.paidSocial.services,
//         specificFields: productStrategy.paidSocial.active
//           ? [...(acc.paidSocial.specificFields || []), ...(productStrategy.paidSocial.specificFields || [])]
//           : acc.paidSocial.specificFields,
//       },
//       // Continue similar logic for other sections...
//       seo: {
//         active: acc.seo.active || productStrategy.seo.active,
//         services: productStrategy.seo.active
//           ? [...acc.seo.services, ...productStrategy.services.filter(hasValidService)]
//           : acc.seo.services,
//         specificFields: productStrategy.seo.active
//           ? [...(acc.seo.specificFields || []), ...(productStrategy.seo.specificFields || [])]
//           : acc.seo.specificFields,
//       },
//       display: {
//         active: acc.display.active || productStrategy.display.active,
//         services: productStrategy.display.active
//           ? [...acc.display.services, ...productStrategy.services.filter(hasValidService)]
//           : acc.display.services,
//         specificFields: productStrategy.display.active
//           ? [...(acc.display.specificFields || []), ...(productStrategy.display.specificFields || [])]
//           : acc.display.specificFields,
//       },
//       // Continue for other fields...
//       addtlInfo: { active: acc.addtlInfo.active || productStrategy.addtlInfo.active },
//       integration: { active: acc.integration.active || productStrategy.integration.active },
//       searchCampaign: { active: acc.searchCampaign.active || productStrategy.searchCampaign.active },
//       socialCampaign: { active: acc.socialCampaign.active || productStrategy.socialCampaign.active },
//       socialAds: { active: acc.socialAds.active || productStrategy.socialAds.active },
//     }
//   }, initialState)
// }

import React from 'react'
import { Box, Grid, Typography, InputLabel, useTheme, TextField } from '@mui/material'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import RenderStrategyHeader from './RenderStrategyHeader'
import RenderServiceIcons from '../RenderServiceIcons/RenderServiceIcons'
import RenderStrategySection from './RenderStrategySection'
import { editFieldConfig } from './editFieldConfig'
import RenderServiceFieldSection from './RenderServiceFieldSection'

const StrategyContentSection = ({
  generalInfo,
  setGeneralInfo,
  addtlInfo,
  integration,
  seo,
  paidSearch,
  paidSocial,
  display,
  smm,
  isEditMode,
  setIsEditMode,
  setAddtlInfo,
  setIntegration,
  setSeo,
  setPaidSearch,
  setPaidSocial,
  setDisplay,
  setSmm,
  strategyState,
  onSave,
}) => {
  const theme = useTheme()
  const handleSectionChange = setSectionState => (fieldName, value) => {
    setSectionState(prev => ({
      ...prev,
      [fieldName]: value,
    }))
  }
  const handleServiceFieldChange = setSectionState => (instanceId, fieldName, value) => {
    console.log('INSTANCE ID: ', instanceId, 'fieldName', fieldName, 'value: ', value)
    setSectionState(prev => {
      const updatedServices = prev.services.map(service => {
        // If this is the service we want to update, update its specific fields
        if (service.instanceId === instanceId) {
          const updatedSpecificFields = service.specificFields.map(field =>
            field.fieldName === fieldName ? { ...field, value } : field
          )
          return { ...service, specificFields: updatedSpecificFields }
        }
        return service // Return the unchanged service for others
      })

      // Return the updated state with modified services
      return { ...prev, services: updatedServices }
    })
  }

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 25,
          mb: 1,
          bgcolor: theme.palette.background.default,
          border: '1px solid',
        }}
      >
        <RenderStrategyHeader
          formState={generalInfo}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          onSave={onSave}
          onChange={setGeneralInfo}
        />
      </Box>
      {strategyState.addtlInfo.active && (
        <RenderStrategySection
          page="Addt'l Info"
          fields={editFieldConfig["Addt'l Info"]}
          formState={addtlInfo}
          isEditMode={isEditMode}
          onChange={handleSectionChange(setAddtlInfo)}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // p: 1,
          border: '1px solid',
          borderColor: theme.palette.text.secondary,
          bgcolor: theme.palette.background.main,
          position: 'sticky',
          top: 'calc(180px)',
          zIndex: 20, // Adjust stacking order
        }}
      >
        <Box sx={{ width: '100%', bgcolor: theme.palette.background.divider }}>
          <Typography ml={1} variant='h8' gutterBottom>
            {'Integration'}
          </Typography>
        </Box>
      </Box>
      {strategyState.integration && strategyState.integration.active && (
        <RenderStrategySection
          page='Integration'
          fields={editFieldConfig['Integration']}
          formState={integration}
          isEditMode={isEditMode}
          onChange={handleSectionChange(setIntegration)}
        />
      )}
      {/* Scrollable Product Section */}
      {/* Paid Search */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // p: 1,
          border: '1px solid',
          borderColor: theme.palette.text.secondary,
          bgcolor: theme.palette.background.main,
          position: 'sticky',
          top: 'calc(180px + 24px)',
          zIndex: 19, // Adjust stacking order
        }}
      >
        <Box sx={{ width: '100%', bgcolor: theme.palette.background.divider }}>
          <Typography ml={1} variant='h8' gutterBottom>
            {'Paid Search'}
          </Typography>
        </Box>
      </Box>
      <Box>
        {strategyState &&
        Array.isArray(strategyState.paidSearch.services) &&
        strategyState.paidSearch.services.length > 0 ? (
          <>
            {strategyState.paidSearch.services.map((service, index) => (
              <Box key={service.instanceId}>
                {/* Render the product details */}
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    p: 1,
                    border: '1px solid',
                    borderColor: theme.palette.text.secondary,
                    bgcolor: theme.palette.background.main,
                    zIndex: 17, // Adjust stacking order
                  }}
                >
                  <Grid item xs={4} sx={{ display: 'flex', gap: 1 }}>
                    <RenderServiceIcons services={[service.productName]} />
                    <Typography>{service.productName}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel sx={{ fontSize: 12 }}>Payer</InputLabel>
                    <Typography>{service.payer}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel sx={{ fontSize: 12 }}>Account Used</InputLabel>
                    <Typography>{service.accountUsed}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel sx={{ fontSize: 12 }}>Media Spend</InputLabel>
                    <Typography>{formatCurrency(service.mediaSpend)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel sx={{ fontSize: 12 }}>Price</InputLabel>
                    <Typography>{formatCurrency(service.price)}</Typography>
                  </Grid>
                </Grid>

                {/* Render the specific fields for this service */}
                {/* Render the specific fields for this service */}
                <RenderServiceFieldSection
                  fields={service.specificFields}
                  formState={paidSearch} // Pass the entire paidSearch state, indexed by instanceId
                  isEditMode={isEditMode}
                  onChange={(instanceId, fieldName, value) =>
                    handleServiceFieldChange(setPaidSearch)(instanceId, fieldName, value)
                  }
                />
              </Box>
            ))}
          </>
        ) : null}
      </Box>

      {/* Paid Social */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // p: 1,
          border: '1px solid',
          borderColor: theme.palette.text.secondary,
          bgcolor: theme.palette.background.main,
          position: 'sticky',
          top: 'calc(180px + 24px)',
          zIndex: 19, // Adjust stacking order
        }}
      >
        <Box sx={{ width: '100%', bgcolor: theme.palette.background.divider }}>
          <Typography ml={1} variant='h8' gutterBottom>
            {'Paid Social'}
          </Typography>
        </Box>
      </Box>
      <Box>
        {strategyState &&
        Array.isArray(strategyState.paidSocial.services) &&
        strategyState.paidSocial.services.length > 0 ? (
          <>
            {strategyState.paidSocial.services.map((service, index) => (
              <Box key={index}>
                {/* Render the product details */}
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    p: 1,
                    border: '1px solid',
                    borderColor: theme.palette.text.secondary,
                    bgcolor: theme.palette.background.main,
                    zIndex: 17, // Adjust stacking order
                  }}
                >
                  <Grid item xs={4} sx={{ display: 'flex', gap: 1 }}>
                    <RenderServiceIcons services={[service.productName]} />
                    <Typography>{service.productName}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel sx={{ fontSize: 12 }}>Payer</InputLabel>
                    <Typography>{service.payer}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel sx={{ fontSize: 12 }}>Account Used</InputLabel>
                    <Typography>{service.accountUsed}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel sx={{ fontSize: 12 }}>Media Spend</InputLabel>
                    <Typography>{formatCurrency(service.mediaSpend)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel sx={{ fontSize: 12 }}>Price</InputLabel>
                    <Typography>{formatCurrency(service.price)}</Typography>
                  </Grid>
                </Grid>

                {/* Render the specific fields for this service */}
                <RenderServiceFieldSection
                  fields={service.specificFields}
                  formState={paidSocial} // Pass the entire paidSearch state, indexed by instanceId
                  isEditMode={isEditMode}
                  onChange={(instanceId, fieldName, value) =>
                    handleServiceFieldChange(setPaidSocial)(instanceId, fieldName, value)
                  }
                />
              </Box>
            ))}
          </>
        ) : null}
      </Box>
      {/* Display */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // p: 1,
          border: '1px solid',
          borderColor: theme.palette.text.secondary,
          bgcolor: theme.palette.background.main,
          position: 'sticky',
          top: `calc(180px + 24px + 24px + 24px)`, // Paid Search + Paid Social headers
          zIndex: 17, // Adjust stacking order
        }}
      >
        <Box sx={{ width: '100%', bgcolor: theme.palette.background.divider }}>
          <Typography ml={1} variant='h8' gutterBottom>
            {'Display'}
          </Typography>
        </Box>
      </Box>
      <Box>
        {strategyState && Array.isArray(strategyState.display.services) && strategyState.display.services.length > 0 ? (
          <>
            {strategyState && Array.isArray(strategyState.display.services)
              ? strategyState.display.services.map((service, index) => (
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      p: 1,
                      border: '1px solid',
                      borderColor: theme.palette.text.secondary,
                      bgcolor: theme.palette.background.main,
                      // position: 'sticky',
                      zIndex: 12, // Adjust stacking order
                    }}
                  >
                    <Grid item xs={4} sx={{ display: 'flex', gap: 1 }}>
                      <RenderServiceIcons services={[service.productName]} />
                      <Typography>{service.productName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Payer</InputLabel>
                      <Typography>{service.payer}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Account Used</InputLabel>
                      <Typography>{service.accountUsed}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Media Spend</InputLabel>
                      <Typography>{formatCurrency(service.mediaSpend)}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Price</InputLabel>
                      <Typography>{formatCurrency(service.price)}</Typography>
                    </Grid>
                  </Grid>
                ))
              : null}
          </>
        ) : null}
        {strategyState.display.active && (
          <Box zIndex={1}>
            <RenderStrategySection
              page='Display'
              fields={editFieldConfig['Display']}
              formState={display}
              isEditMode={isEditMode}
              onChange={handleSectionChange(setDisplay)}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // p: 1,
          border: '1px solid',
          borderColor: theme.palette.text.secondary,
          bgcolor: theme.palette.background.main,
          position: 'sticky',
          top: `calc(180px + 24px + 24px + 24px + 24px)`, // Paid Search + Paid Social headers
          zIndex: 17, // Adjust stacking order
        }}
      >
        <Box sx={{ width: '100%', bgcolor: theme.palette.background.divider }}>
          <Typography ml={1} variant='h8' gutterBottom>
            {'SEO'}
          </Typography>
        </Box>
      </Box>
      <Box>
        {strategyState && Array.isArray(strategyState.seo.services) && strategyState.seo.services.length > 0 ? (
          <>
            {strategyState && Array.isArray(strategyState.seo.services)
              ? strategyState.seo.services.map((service, index) => (
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      p: 1,
                      border: '1px solid',
                      borderColor: theme.palette.text.secondary,
                      bgcolor: theme.palette.background.main,
                      // position: 'sticky',
                      zIndex: 12, // Adjust stacking order
                    }}
                  >
                    <Grid item xs={4} sx={{ display: 'flex', gap: 1 }}>
                      <RenderServiceIcons services={[service.productName]} />
                      <Typography>{service.productName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Payer</InputLabel>
                      <Typography>{service.payer}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Account Used</InputLabel>
                      <Typography>{service.accountUsed}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Media Spend</InputLabel>
                      <Typography>{formatCurrency(service.mediaSpend)}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Price</InputLabel>
                      <Typography>{formatCurrency(service.price)}</Typography>
                    </Grid>
                  </Grid>
                ))
              : null}
          </>
        ) : null}
        {strategyState.seo.active && (
          <Box zIndex={1}>
            <RenderStrategySection
              page='SEO'
              fields={editFieldConfig['SEO']}
              formState={seo}
              isEditMode={isEditMode}
              onChange={handleSectionChange(setSeo)}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // p: 1,
          border: '1px solid',
          borderColor: theme.palette.text.secondary,
          bgcolor: theme.palette.background.main,
          position: 'sticky',
          top: `calc(180px + 24px + 24px + 24px + 24px + 24px)`, // Paid Search + Paid Social headers
          zIndex: 17, // Adjust stacking order
        }}
      >
        <Box sx={{ width: '100%', bgcolor: theme.palette.background.divider }}>
          <Typography ml={1} variant='h8' gutterBottom>
            {'SMM'}
          </Typography>
        </Box>
      </Box>
      <Box>
        {strategyState && Array.isArray(strategyState.smm.services) && strategyState.smm.services.length > 0 ? (
          <>
            {strategyState && Array.isArray(strategyState.smm.services)
              ? strategyState.smm.services.map((service, index) => (
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      p: 1,
                      border: '1px solid',
                      borderColor: theme.palette.text.secondary,
                      bgcolor: theme.palette.background.main,
                      // position: 'sticky',
                      zIndex: 12, // Adjust stacking order
                    }}
                  >
                    <Grid item xs={4} sx={{ display: 'flex', gap: 1 }}>
                      <RenderServiceIcons services={[service.productName]} />
                      <Typography>{service.productName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Payer</InputLabel>
                      <Typography>{service.payer}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Account Used</InputLabel>
                      <Typography>{service.accountUsed}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Media Spend</InputLabel>
                      <Typography>{formatCurrency(service.mediaSpend)}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel sx={{ fontSize: 12 }}>Price</InputLabel>
                      <Typography>{formatCurrency(service.price)}</Typography>
                    </Grid>
                  </Grid>
                ))
              : null}
          </>
        ) : null}
        {strategyState.smm.active && (
          <Box zIndex={1}>
            <RenderStrategySection
              page='SMM'
              fields={editFieldConfig['SMM']}
              formState={smm}
              isEditMode={isEditMode}
              onChange={handleSectionChange(setSmm)}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default StrategyContentSection

import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Table, TableBody, TableContainer } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import useTotals from '../../api/customHooks/useTotals'
import EditDialogHeaderComponent from '../DialogHeader/EditDialogHeader'
import ContractTotals from '../ContractDialog/ContractTotals'
import { usePandaContext } from '../../context/PandaProvider/PandaProvider'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import RenderRow from '../ContractDialog/RenderRow'
import EditDealInfo from './EditDealInfo'
import { InfoBoxContainer } from './style'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useActiveUser } from '../../api/slack/useActiveUser'
import DetailsLoading from '../Loading/DetailsLoading'
import { handleEditDealState } from '../DealApproval/determineApprovalState'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import isEqual from 'lodash.isequal'
import { useParams } from 'react-router-dom'
import ContractHeaderComponent from '../DialogHeader/ContractHeaderComponent'
import ContractInfoHeader from '../ContractDialog/ContractInfoHeader'
import { BoxScroller } from '../../style/styleElements'
import ProductRowsTable from '../ProductRows/ProductRowsTable'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)

const RenderContract = ({ onSaveDeal, onClose, open, onUpdate, isEditMode }) => {
  const theme = useTheme()
  const { dealId } = useParams()
  const {
    newDeal,
    setNewDeal,
    validateFields,
    validationErrors,
    productRows,
    setProductRows,
    computeTotals,
    isValid,
    setIsValid,
    addRow,
    removeRow,
    handleProductChange,
    handleChange,
    originalDeal,
    originalApprovalState,
    approvalState,
    setApprovalState,
  } = useDealDetailsContext()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(
    newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : null
  )
  const [originalType, setOriginalType] = useState(newDeal.type || '')
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal.type)
  const { mutate: createDocument } = useCreateDocument()
  const { mutate: updateDeal } = useUpdateDeal()
  const { products, productsLoading, productsError } = usePandaContext()
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { showSnackbar } = useSnackbarContext()
  const {
    organization: contractedBy,
    isOrganizationLoading: isContractedByLoading,
    isOrganizationError: isContractedByError,
  } = useOrganizationById(organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : [])

  useEffect(() => {
    if (open) {
      const isValid = validateFields(newDeal)
      setIsValid(isValid)
    }
  }, [open, newDeal])
  const handleSave = async e => {
    e.preventDefault()
    setIsCreating(true)
    const hasProducts = newDeal && Array.isArray(newDeal.products) && newDeal.products.length > 0
    const updatedDeal = {
      ...newDeal,
      ...totals,
      hasProducts: hasProducts,
      totals: { ...totals },
    }
    console.log('UPDATED DEAL: ', updatedDeal)
    try {
      await onSaveDeal(updatedDeal, organization, hasProducts)
      setIsCreating(false)
    } catch (error) {
      setIsCreating(false)
      console.error(error)
    }
  }

  const submitContract = (cleanedDeal, organization, activeUser) => {
    try {
      const documentData = {
        deal: cleanedDeal,
        organization,
        activeUser,
      }

      createDocument(documentData, {
        onSuccess: data => {
          const contractId = data.id
          const dealWithContractId = {
            ...cleanedDeal,
            contractId: contractId,
            status: 'Created',
            stage: 'Contract Created',
            lastModified: new Date(),
          }
          updateDeal(
            {
              dealId: dealWithContractId.deal_uuid,
              dealData: dealWithContractId,
            },
            {
              onSuccess: () => {
                setIsCreating(false)
                onClose()
              },
              onError: error => {
                console.error('Error in updateDeal:', error)
                setIsCreating(false)
              },
            }
          )
          showSnackbar('Document successfully created', 'success')
        },
        onError: error => {
          console.error('Error in createDocument:', error)
          setIsCreating(false)
          setIsError(true)
        },
      })
    } catch (error) {
      console.error('Error in submitContract try-catch:', error)
      setIsCreating(false)
      setIsError(true)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    setIsCreating(true)
    try {
      submitContract({ ...newDeal, creation_timestamp: new Date(), isCreated: true }, organization, activeUser)
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      setIsCreating(false)
      setIsError(true)
    }
    setIsCreating(false)
  }
  const onDragEnd = result => {
    if (!result.destination) return
    const items = Array.from(productRows)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setProductRows(items)
  }

  const onChange = (fieldName, value) => {
    handleChange(fieldName, value, organization)
  }
  useEffect(() => {
    if (newDeal.hasProducts) {
      console.log(newDeal.hasProducts)
    }
  }, [newDeal.hasProducts])
  const isLoading = isOrganizationLoading || isContractedByLoading
  if (isLoading) {
    return <DetailsLoading />
  }

  return (
    <>
      <Grid container>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            bgcolor: theme.palette.background.main,
            p: 2,
          }}
        >
          <Grid item xs={5} sm={7} md={12} bgcolor={theme.palette.background.main}>
            <EditDealInfo
              key={dealId}
              selectedDeal={newDeal}
              setSelectedDeal={setNewDeal}
              organization={organization}
              approvalState={approvalState}
              contractedBy={contractedBy}
              onUpdate={onUpdate}
              isCreating={isCreating}
              onClose={onClose}
              onSave={handleSave}
              isValid={isValid}
              onCreate={handleSubmit}
              validationErrors={validationErrors}
            />
          </Grid>

          <Grid item xs={5} sm={7} md={12} bgcolor={theme.palette.background.main}>
            <ContractInfoHeader
              newDeal={newDeal}
              setNewDeal={setNewDeal}
              handleChange={onChange}
              validationErrors={validationErrors}
              organization={organization}
            />
          </Grid>
        </Box>

        {/* Product Table */}
        <Grid item xs={5} sm={7} md={12} p={2}>
          <ProductRowsTable
            newDeal={newDeal}
            productRows={productRows}
            onProductChange={handleProductChange}
            validationErrors={validationErrors}
            removeRow={removeRow}
            type={newDeal ? newDeal.type : ''}
          />
        </Grid>
        <Box
          sx={{
            position: 'sticky', // Fixed positioning to keep it at the bottom
            bottom: 0, // Sticks to the bottom of the viewport
            zIndex: 12,
            bgcolor: theme.palette.background.main, // Ensure a solid background
            width: '100%', // Ensure it spans the full width
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            // padding: 2,
          }}
        >
          {/* Add Service Button */}
          <Grid item xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            <Button
              sx={{
                // marginTop: 2,
                marginBottom: 4,
                // pb: 4,
                '&:hover': {
                  opacity: '80%',
                },
              }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={addRow}
            >
              Add Service
            </Button>
          </Grid>
          <Grid item xs={12} height={'100%'} pb={2}>
            <ContractTotals
              newDeal={newDeal}
              setNewDeal={setNewDeal}
              totals={totals}
              onChange={handleChange}
              margineft={'0px'}
            />
          </Grid>
        </Box>
      </Grid>
    </>
  )
}

export default RenderContract

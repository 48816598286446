export const editFieldConfig = {
  'General Information': [
    {
      name: 'organizationName',
      label: 'Organization Name',
      type: 'text',
      required: true,
      fullLabel: 'Name',
      readOnly: true,
      path: 'generalInfo.organizationName',
    },
    {
      name: 'vertical',
      label: 'Vertical',
      type: 'text',
      required: true,
      fullLabel: 'Vertical',
      readOnly: true,
      path: 'generalInfo.vertical',
    },
    {
      name: 'type',
      label: 'Contract Type',
      type: 'text',
      required: true,
      fullLabel: 'Contract Type',
      readOnly: true,
    },
    {
      name: 'website',
      label: 'Website',
      type: 'url',
      required: true,
      fullLabel: 'Website',
      readOnly: true,
    },
    {
      name: 'groContact',
      label: 'GRO Email',
      type: 'email',
      required: true,
      fullLabel: 'GRO Email',
      readOnly: true,
    },
    {
      name: 'orgContact',
      label: 'Contact Email',
      type: 'email',
      required: true,
      fullLabel: 'Contact Email',
      readOnly: true,
    },
  ],
  "Addt'l Info": [
    // {
    //   name: 'busObjective',
    //   label: 'Business Objective',
    //   type: 'select',
    //   options: ['Leads', 'Branding/awareness', 'In-Store Visits', 'Website traffic'],
    //   required: true,
    //   fullLabel: `Business Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
    //   placeholder: 'Business Objective',
    // },
    {
      name: 'productsAndServices',
      label: 'Products and services to focus on',
      type: 'text',
      required: true,
      fullLabel: 'Products and services to focus on',
      placeholder: 'HVAC (heating & AC, plumbing, electrical)',
      // readOnly: true,
    },
    {
      name: 'marketingObjective',
      label: 'Marketing Objective',
      type: 'text',
      required: true,
      fullLabel: `Marketing Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
      placeholder: 'Marketing Objective',
      // readOnly: true,
    },
    {
      name: 'geoTargeting',
      label: 'Geographic Targeting',
      type: 'text',
      required: true,
      fullLabel: 'Geographic Targeting <br /> a. State, City, Zip codes, etc',
      // readOnly: true,
    },
    {
      name: 'demographicTargeting',
      label: 'Demographic Targeting',
      type: 'text',
      required: true,
      fullLabel: 'Search Demographic Targeting',
    },
    {
      name: 'cmsUsed',
      label: 'CMS Being Used',
      type: 'text',
      required: true,
      fullLabel: 'e.g. Wordpress, entrata, squarespace',
      // readOnly: true,
    },
    {
      name: 'callTracking',
      label: 'Is the client using call tracking?',
      type: 'radio',
      options: ['Yes', 'No'],
      required: true,
      fullLabel: 'Is the client using call tracking?',
    },
  ],
  Integration: [
    {
      name: 'targetingSpecifics',
      label: 'Targeting Specifics',
      type: 'text',
      required: false,
      fullLabel: 'Search Targeting Specifics',
    },
    {
      name: 'formPresent',
      label: 'Is there a third party form present on website?',
      type: 'text',
      required: false,
      fullLabel: 'Is there a third party form present on website?',
    },
  ],
  'Paid Search': [
    {
      name: 'landingPageUrl',
      label: 'Landing Page URL',
      type: 'text',
      required: false,
      fullLabel: 'Search Landing Page URL',
    },
    {
      name: 'searchMarketingObjective',
      label: 'Marketing Objective',
      type: 'text',
      required: true,
      fullLabel: `Marketing Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
      placeholder: 'Marketing Objective',
      readOnly: true,
    },
    // {
    //   name: 'googleAdsCallExtension',
    //   label: 'Number for Google ads call extensions?',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'What is the number the client provided us for Google ads call extensions?',
    // },
  ],
  'Paid Social': [
    // {
    //   name: 'facebookAccess',
    //   label: 'Does the client have the ability to share access to the Facebook page?',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'Does the client have access to their Facebook page?',
    // },
    // {
    //   name: 'services',
    //   label: 'Services',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'Services being used for paid social',
    // },
    {
      name: 'socialMarketingObjective',
      label: 'Marketing Objective',
      type: 'text',
      required: true,
      fullLabel: `Marketing Objective <br /> a. What is the client's overall marketing objective? Do they want Leads, Branding/awareness, In-Store Visits, Website traffic or a mix of these? <br /> i. Keep in mind that more than one objective should come with more budget`,
      placeholder: 'Marketing Objective',
      readOnly: true,
    },
    // {
    //   name: 'googleBusinessProfile',
    //   label: 'Does the client have a Google Business profile Setup?',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'Does the client have a Google Business profile Setup?',
    // },
    {
      name: 'socialLandingPageUrl',
      label: 'Landing Page URL',
      type: 'text',
      required: false,
      fullLabel: 'Search Landing Page URL',
    },
  ],
  Display: [
    // {
    //   name: 'displayPlatform',
    //   label: 'Display Platform',
    //   type: 'text',
    //   required: false,
    //   helperText: 'Simplifi, Google Display, Spotify(Includes Video: CTV, Youtube ads)',
    //   fullLabel: 'Display Platform',
    // },
    // {
    //   name: 'Does client have margin on Display?',
    //   label: '',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'Does client have margin on Display?',
    // },
    // {
    //   name: 'displayMediaSpend',
    //   label: 'Monthly Media Spend',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'Monthly Media Spend',
    // },
    // {
    //   name: 'displayDemoTargeting',
    //   label: 'Demo Targeting',
    //   type: 'text',
    //   required: false,
    //   fullLabel: 'Demographic Targeting',
    // },
    {
      name: 'displayLandingPage',
      label: 'Landing Page URL',
      type: 'text',
      required: false,
      fullLabel: 'Landing Page URL?',
    },
    {
      name: 'displayGeoTargeting',
      label: 'Geo Targeting',
      type: 'text',
      required: false,
      fullLabel: 'Geographic Targeting',
    },
    {
      name: 'displayTargetingSpecifics',
      label: 'Targeting Specifics',
      type: 'text',
      required: false,
      fullLabel: 'Targeting Specifics',
    },
  ],
  SEO: [
    {
      name: 'cmsLogin',
      label: 'CMS Login',
      type: 'text',
      required: true,
      fullLabel: 'CMS login info',
    },
    {
      name: 'street',
      label: 'Street',
      type: 'text',
      required: true,
      fullLabel: "Organization's Street Address",
      readOnly: true,
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
      required: true,
      fullLabel: "Organization's City",
      readOnly: true,
    },
    {
      name: 'state',
      label: 'State',
      type: 'text',
      required: true,
      fullLabel: "Organization's State",
      readOnly: true,
    },
    {
      name: 'zip',
      label: 'Zip',
      type: 'text',
      required: true,
      fullLabel: "Organization's Zip Code",
      readOnly: true,
    },
    {
      name: 'cmsUsed',
      label: 'CMS Used',
      type: 'text',
      required: true,
      fullLabel: "Organization's CMS",
      readOnly: true,
    },
  ],
  SMM: [
    {
      name: 'SMM1',
      label: 'Is their Google Business profile up to date?',
      type: 'text',
      required: false,
      fullLabel: 'Is their Google Business profile up to date?',
    },
  ],
  // Campaigns: [
  //   {
  //     name: 'platform',
  //     label: 'Platform',
  //     type: 'text',
  //     required: true,
  //     fullLabel: 'Platform',
  //   },
  //   {
  //     name: 'name',
  //     label: 'Campaign Name',
  //     type: 'text',
  //     required: true,
  //     fullLabel: 'Campaign Name',
  //   },
  //   {
  //     name: 'location',
  //     label: 'Location',
  //     type: 'text',
  //     required: true,
  //     fullLabel: 'Location (presence and interest)',
  //   },
  //   {
  //     name: 'adGroup',
  //     label: 'Ad Group',
  //     type: 'text',
  //     required: true,
  //     fullLabel: 'Ad Group',
  //   },
  //   {
  //     name: 'keywords',
  //     label: 'Keywords',
  //     type: 'text',
  //     required: true,
  //     fullLabel: 'Keywords',
  //     placeholder: 'Enter keywords separated by commas',
  //   },
  //   {
  //     name: 'negativeKeywords',
  //     label: 'Negative Keywords',
  //     type: 'text',
  //     required: false,
  //     fullLabel: 'Negative Keywords',
  //     placeholder: 'Enter negative keywords separated by commas',
  //   },
  // ],
}
// productFieldsConfig.js
export const productFieldsConfig = {
  'Meta Advertising': [
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    { fieldName: 'audienceTargeting', label: 'Audience Targeting' },
  ],
  'Linkedin Advertising': [
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    { fieldName: 'audienceTargeting', label: 'Audience Targeting' },
  ],
  'Spotify Advertising': [
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    { fieldName: 'audienceTargeting', label: 'Audience Targeting' },
  ],
  'TikTok Advertising': [
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    { fieldName: 'audienceTargeting', label: 'Audience Targeting' },
  ],
  'SnapChat Advertising': [
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    { fieldName: 'audienceTargeting', label: 'Audience Targeting' },
  ],
  'Paid Search Advertising': [
    { fieldName: 'landingPageUrl', label: 'Landing Page URL' },
    { fieldName: 'marketingObjective', label: 'Marketing Objective' },
  ],
  // Add other product-specific fields here
}
export const socialProducts = [
  'Meta Advertising',
  'Linkedin Advertising',
  'Spotify Advertising',
  'TikTok Advertising',
  'SnapChat Advertising',
  'Social Media Advertising Management Fee',
  'Linkedin Advertising',
  'Paid Social Management Fee',
]
export const searchProducts = [
  'Paid Search Advertising',
  'Paid Search Management Fee',
  'Paid Search - Media Spend',
  'Microsoft Advertising',
  'Amazon Advertising',
  'Agency Retainer - Digital',
  'Management Fee / Retainer',
]

import React, { useState, useEffect } from 'react'
import { AvatarGroup, Avatar, Box, useTheme } from '@mui/material'
import { useUsers } from '../../api/aws/useUsers'

const RenderApprovers = ({ approverIds, densityStyles }) => {
  const theme = useTheme()
  const additionalAvatarBgColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const { users, isUsersLoading, isUsersError } = useUsers()
  const approvers = users.filter(user => approverIds.includes(user.id))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        height: '100%',
        padding: densityStyles.padding,
        gap: densityStyles.gap,
      }}
    >
      <AvatarGroup
        max={5}
        spacing={'medium'}
        slotProps={{
          additionalAvatar: {
            sx: {
              height: densityStyles.avatarSize,
              width: densityStyles.avatarSize,
              fontSize: densityStyles.fontSize,
              backgroundColor: additionalAvatarBgColor,
            },
          },
        }}
      >
        {approvers.map(member => (
          <Avatar
            key={member.id}
            alt={member.real_name}
            src={member.image_512}
            sx={{ height: densityStyles.avatarSize, width: densityStyles.avatarSize }}
          />
        ))}
      </AvatarGroup>
    </Box>
  )
}

export default RenderApprovers

import React from 'react'
import { Grid, Box, Typography, TextField, useTheme, Stack, InputLabel } from '@mui/material'
import RenderServiceIcons from '../RenderServiceIcons/RenderServiceIcons'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'

const RenderStrategySection = ({ page, fields, formState, isEditMode, onChange }) => {
  const handleChange = (fieldName, newValue) => {
    onChange(fieldName, newValue) // Update the specific field within the section's form state
  }

  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          border: '1px solid',
          borderColor: theme.palette.text.secondary,
        }}
      >
        <Grid
          container
          p={0.5}
          sx={{
            backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(255, 255, 255)',
            borderRadius: 1,
            alignItems: 'center',
          }}
        >
          {fields.map(field => (
            <Grid item xs={12} sm={6} key={field.name}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    ml: 2,
                    width: '40%',
                  }}
                  variant='subtitle1'
                >
                  {field.label}
                </Typography>

                {isEditMode ? (
                  <TextField
                    name={field.name}
                    value={formState[field.name] || ''}
                    onChange={e => handleChange(field.name, e.target.value)}
                    fullWidth
                    variant='outlined'
                    disabled={field.readOnly}
                    helperText={field.placeholder}
                    size='small'
                    sx={{
                      flexGrow: 1,
                      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(44, 51, 61)' : 'rgb(255, 255, 255)',
                      borderRadius: '4px',
                      mb: 0.5,
                    }}
                  />
                ) : (
                  <Typography
                    sx={{
                      flexGrow: 1,
                      padding: '8px 16px',
                      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(44, 51, 61)' : 'rgb(245, 245, 245)',
                      borderRadius: '4px',
                      fontSize: '0.875rem',
                      color: theme.palette.mode === 'dark' ? 'rgb(181, 191, 205)' : 'rgb(97, 97, 97)',
                      mb: 0.5,
                    }}
                  >
                    {formState[field.name] || '—'}
                  </Typography>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default RenderStrategySection

import React, { useEffect, useState } from 'react'
import { Grid, Box, Button, useTheme, Stack, Typography, InputLabel } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import ViewStrategyForm from './ViewStrategyForm'
import { useOrganizationById } from '../../api/aws/useOrganization'
import RenderStrategyHeader from './RenderStrategyHeader'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import StrategyNotes from './StrategyNotes'
import DetailsLoading from '../Loading/DetailsLoading'
// import { determineStrategyState } from './determineStrategyfields'
import { determineStrategyState } from './determineStrategy'
import useSaveDeal from '../../api/customHooks/useSaveDeal'
import StrategyNotesHeader from './StrategyNotesHeader'
import { v4 as uuidv4 } from 'uuid' // For generating unique note IDs
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useActiveUser } from '../../api/slack/useActiveUser'
import {
  createSearchCampaigns,
  createSocialAds,
  createSocialCampaigns,
  searchCampaigns,
  socialCampaigns,
} from './templates'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import RenderServiceIcons from '../RenderServiceIcons/RenderServiceIcons'
import StrategyNotesSection from './StrategyNotesSection'
import StrategyContentSection from './StrategyContentSection'

const Strategy = () => {
  const { dealId } = useParams()
  const { newDeal: deal } = useDealDetailsContext()
  const orgId = deal?.organizations?.[0] || null
  const { organization, isOrganizationLoading } = useOrganizationById(orgId)
  const { mutate: saveDeal } = useSaveDeal()
  const theme = useTheme()
  //////// Notes ///////////
  const { activeUser } = useActiveUser()
  const { showSnackbar } = useSnackbarContext()
  const { mutate: updateItems } = useUpdateItems()
  const [newNoteContent, setNewNoteContent] = useState('')
  const [notes, setNotes] = useState([])
  const [isCreatingNote, setIsCreatingNote] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  // Separate states for each section
  const [generalInfo, setGeneralInfo] = useState({})
  const [paidSearch, setPaidSearch] = useState({})
  const [paidSocial, setPaidSocial] = useState({})
  const [display, setDisplay] = useState({})
  const [smm, setSmm] = useState({})
  const [addtlInfo, setAddtlInfo] = useState({})
  const [integration, setIntegration] = useState({})
  const [seo, setSeo] = useState({})
  const [searchCampaigns, setSearchCampaigns] = useState([])
  const [socialCampaigns, setSocialCampaigns] = useState([])
  const [socialAds, setSocialAds] = useState([])
  const [strategyState, setStrategyState] = useState({
    paidSearch: { active: false, services: [] },
    paidSocial: { active: false, services: [] },
    seo: { active: false, services: [] },
    smm: { active: false, services: [] },
    website: { active: false, services: [] },
    display: { active: false, services: [] },
    creative: { active: false, services: [] },
    addtlInfo: { active: true, services: [] },
    integration: { active: true, services: [] },
    searchCampaign: { active: false, services: [] },
    socialCampaign: { active: false, services: [] },
  })

  useEffect(() => {
    if (deal && organization) {
      console.log('DEAL: ', deal)
      setGeneralInfo({
        organizationName: deal?.generalInfo?.organizationName || organization?.name || '',
        website: deal?.generalInfo?.website || organization?.website || '',
        type: deal?.type || '',
        vertical: deal?.generalInfo?.vertical || organization?.vertical?.[0] || '',
        groContact: deal?.recipients?.authorizedRecipient?.[0]?.email || '',
        orgContact: deal?.recipients?.contactRecipient?.[0]?.email || '',
      })
      setAddtlInfo({
        marketingObjective: organization?.marketingObjective || '',
        geoTargeting: organization?.geoTargeting || '',
        cmsUsed: organization?.cmsUsed || '',
        demographicTargeting: deal?.addtlInfo?.demographicTargeting || organization?.demographicTargeting || '',
        productsAndServices: organization?.productsAndServices || '',
        callTracking: deal?.addtlInfo?.callTracking || '',
      })
      setIntegration({
        targetingSpecifics: deal?.integration?.targetingSpecifics || organization?.targetingSpecifics || '',
        formPresent: deal?.integration?.formPresent || '',
      })
      // Initialize paid search with existing data if available
      const paidSearchServices = deal?.paidSearch?.services || []
      setPaidSearch({
        landingPageUrl: deal?.paidSearch?.landingPageUrl || '',
        searchKeywords: deal?.paidSearch?.searchKeywords || '',
        services: paidSearchServices.map(service => ({
          ...service,
          specificFields: service.specificFields.map(field => ({
            ...field,
            value: field.value || '', // Use the existing value if available
          })),
        })),
      })

      // Similarly initialize other sections like paidSocial, display, etc.
      const paidSocialServices = deal?.paidSocial?.services || []
      console.log('paidSocialServices: ', paidSocialServices)
      setPaidSocial({
        services: paidSocialServices.map(service => ({
          ...service,
          specificFields: service.specificFields.map(field => ({
            ...field,
            value: field.value || '', // Use existing value if available
          })),
        })),
        googleBusinessProfile: deal?.paidSocial?.googleBusinessProfile || '',
        socialLandingPageUrl: deal?.paidSocial?.socialLandingPageUrl || '',
      })
      setDisplay({
        displayPlatform: deal?.display?.displayPlatform || '',
        displayLandingPageUrl: deal?.display?.displayLandingPageUrl || '',
        displayMediaSpend: deal?.display?.displayMediaSpend || '',
        displayDemoTargeting: deal?.display?.displayDemoTargeting || '',
        displayLandingPage: deal?.display?.displayLandingPage || '',
        displayGeoTargeting: deal?.display?.displayGeoTargeting || '',
        displayTargetingSpecifics: deal?.display?.displayTargetingSpecifics || '',
      })
      setSeo({
        cmsLogin: deal?.cmsLogin || '',
        street: organization?.street || '',
        city: organization?.city || '',
        state: organization?.state || '',
        zip: organization?.zip || '',
        cmsUsed: organization?.cmsUsed || '',
      })
      setSmm({
        SMM1: deal?.SMM1 || '',
      })
      setStrategyState(determineStrategyState(deal?.products || []))
    }
  }, [deal, organization])
  // // Add Campaign Button Handlers
  // useEffect(() => {
  //   if (strategyState) {
  //     console.log('Strategy State: ', strategyState)

  //     if (strategyState.paidSearch && strategyState.paidSearch.services) {
  //       setPaidSearch(prev => ({ ...prev, services: strategyState.paidSearch.services || [] }))
  //     }
  //     if (strategyState.paidSocial && strategyState.paidSocial.services) {
  //       setPaidSocial(prev => ({ ...prev, services: strategyState.paidSocial.services || [] }))
  //     }
  //     if (strategyState.display && strategyState.display.services) {
  //       setDisplay(prev => ({ ...prev, services: strategyState.display.services || [] }))
  //     }
  //     if (strategyState.seo && strategyState.seo.services) {
  //       setSeo(prev => ({ ...prev, services: strategyState.seo.services || [] }))
  //     }
  //   }
  // }, [strategyState])
  const handleAddSearchCampaign = () => {
    const newCampaign = {
      name: '',
      keywords: [],
      adGroup: '',
      location: '',
    }
    setSearchCampaigns(prev => [...prev, newCampaign])
  }

  const handleAddSocialCampaign = () => {
    const newCampaign = {
      name: '',
      platform: '',
      demographicTargeting: '',
      landingPageUrl: '',
    }
    setSocialCampaigns(prev => [...prev, newCampaign])
  }

  const handleAddSocialAd = () => {
    const newAd = {
      name: '',
      audience: [],
      conversionEvent: '',
      conversionLocation: '',
    }
    setSocialAds(prev => [...prev, newAd])
  }

  const consolidateFields = section => {
    if (!section || !section.services) return section

    return {
      ...section,
      services: section.services.map(service => {
        const instanceSpecificFields = section[service.instanceId] || {}

        return {
          ...service,
          specificFields: service.specificFields.map(field => ({
            fieldName: field.fieldName,
            value: instanceSpecificFields[field.fieldName] || field.value, // Ensure correct form data is captured
          })),
        }
      }),
    }
  }

  const handleSave = () => {
    const searchSection = consolidateFields(paidSearch)
    const socialSection = consolidateFields(paidSocial)
    console.log('searchSection: ', searchSection)
    console.log('socialSection: ', socialSection)
    const dealData = {
      ...deal,
      generalInfo,
      addtlInfo,
      integration,
      seo: consolidateFields(seo),
      paidSearch: consolidateFields(paidSearch),
      paidSocial: consolidateFields(paidSocial),
      display: consolidateFields(display),
      smm: consolidateFields(smm),
      searchCampaigns,
      socialCampaigns,
      socialAds,
    }

    saveDeal(
      { dealId: deal.deal_uuid, dealData },
      {
        onSuccess: () => setIsEditMode(false),
        onError: () => setIsEditMode(false),
      }
    )
  }

  const handleAddNote = () => {
    if (newNoteContent && newNoteContent !== '<p></p>') {
      setIsCreatingNote(true)
      // Avoid adding empty notes
      const newNote = {
        note_id: uuidv4(),
        contents: newNoteContent,
        owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
        createdAt: new Date().toISOString(),
      }

      const updatedNotes = [...notes, newNote]
      setNotes(updatedNotes)

      updateItems(
        {
          updates: [
            {
              primaryKey: 'deal_uuid',
              tableName: 'deals',
              itemId: deal.deal_uuid,
              fieldName: 'notes',
              newValue: updatedNotes,
            },
          ],
        },
        {
          onSuccess: () => {
            showSnackbar('Note added successfully', 'success')
            handleClear()
          },
          onError: err => {
            console.error('Failed to add note:', err)
            showSnackbar('Failed to add note', 'error')
          },
        }
      )
    }
  }
  const handleClear = () => {
    setIsCreatingNote(false)
    setNewNoteContent('') // This clears the note content state
  }
  if (isOrganizationLoading) {
    return <DetailsLoading />
  }

  return (
    <Grid container sx={{ minHeight: 'calc(100vh - 122px)', display: 'flex', flexDirection: 'row', p: 3 }}>
      <Grid item xs={9}>
        <Box
          sx={{
            height: 'calc(100vh - 145px)', // Adjust the height to account for the header and fit within the viewport
            overflowY: 'auto', // Enable vertical scrolling
            pb: 2,
          }}
        >
          <StrategyContentSection
            generalInfo={generalInfo}
            setGeneralInfo={setGeneralInfo}
            addtlInfo={addtlInfo}
            integration={integration}
            seo={seo}
            paidSearch={paidSearch}
            paidSocial={paidSocial}
            display={display}
            smm={smm}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            setAddtlInfo={setAddtlInfo}
            setIntegration={setIntegration}
            setSeo={setSeo}
            setPaidSearch={setPaidSearch}
            setPaidSocial={setPaidSocial}
            setDisplay={setDisplay}
            setSmm={setSmm}
            strategyState={strategyState}
            onSave={handleSave}
          />
        </Box>
      </Grid>
      {/* Right Section: Notes */}
      <Grid item xs={3}>
        <Box
          sx={{
            height: 'calc(100vh - 122px)', // Adjust the height to account for the header and fit within the viewport
            overflowY: 'auto', // Enable vertical scrolling
            paddingRight: '10px', // Add padding to avoid content overflow
          }}
        >
          <StrategyNotesSection
            newNoteContent={newNoteContent}
            setNewNoteContent={setNewNoteContent}
            handleAddNote={handleAddNote}
            handleClear={handleClear}
            isCreatingNote={isCreatingNote}
            setIsCreatingNote={setIsCreatingNote}
            isEditMode={isEditMode}
            notes={notes}
            setNotes={setNotes}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Strategy

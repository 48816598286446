import React from 'react'
import { Box, Button, CircularProgress, IconButton, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import StrategyRichTextField from './StrategyRichTextField'
import SendIcon from '@mui/icons-material/Send'

const StrategyNotesHeader = ({
  newNoteContent,
  setNewNoteContent,
  handleAddNote,
  isCreatingNote,
  setIsCreatingNote,
  handleClear,
}) => {
  const theme = useTheme()
  const hoverBgColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'
  return (
    <Box
      sx={{
        p: 1.2,
        ml: 2.5,
        border: '1px solid',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <StrategyRichTextField
        content={newNoteContent}
        onContentChange={setNewNoteContent}
        isCreatingNote={isCreatingNote}
        setIsCreatingNote={setIsCreatingNote}
        handleAddNote={handleAddNote}
        handleClear={handleClear}
        justify={'left'}
      />
    </Box>
  )
}

export default StrategyNotesHeader

import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box, Typography, useTheme, Tooltip, CircularProgress } from '@mui/material'
import { useParams, Link, Outlet, useLocation } from 'react-router-dom'
import Loading from '../Loading/Loading'
import { useDeal } from '../../api/aws/useDeal'
import DealsDetailsHeader from './DealsDetailsHeader'
import GridLoading from '../Loading/GridLoading'
import { DealDetailsProvider } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import DetailsLoading from '../Loading/DetailsLoading'

const DealPage = () => {
  const { dealId } = useParams()
  const [testLoading, setTestLoading] = useState(true)
  const { deal, isDealLoading, isDealError } = useDeal(dealId)
  const theme = useTheme()
  const location = useLocation()
  const [tabValue, setTabValue] = React.useState(0)

  useEffect(() => {
    if (isDealLoading) {
      console.log('[DealPage] deal loading:')
    }
  }, [isDealLoading])

  useEffect(() => {
    // Determine the tab value based on the current route
    if (location.pathname.includes('/tasks')) {
      setTabValue(1)
    } else if (location.pathname.includes('/services')) {
      setTabValue(2)
    } else if (location.pathname.includes('/strategy')) {
      setTabValue(3)
    } else if (location.pathname.includes('/paymentschedule')) {
      setTabValue(4)
    } else if (location.pathname.includes('/reviewdeal')) {
      setTabValue(5)
    } else if (location.pathname.includes('/invoicing')) {
      setTabValue(6)
    } else {
      setTabValue(0)
    }
  }, [location])

  if (isDealLoading) {
    return <DetailsLoading />
  }

  if (isDealError) {
    return <Typography>Error loading deal details</Typography>
  }

  return (
    <>
      <Box sx={{ position: 'sticky', top: 0, zIndex: 1100 }}>
        <Box
          sx={{
            width: '100%',
          }}
        />
        <DealDetailsProvider dealData={deal}>
          <Box sx={{ display: 'flex', maxWidth: '100vw' }}>
            <DealsDetailsHeader deal={deal} />
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Tabs
              value={tabValue}
              aria-label='deal tabs'
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(255, 255, 255)',
              }}
            >
              <Tab label='Details' component={Link} to={`/deals/${dealId}`} />
              <Tab label='Tasks' component={Link} to={`/deals/${dealId}/tasks`} disabled />
              <Tab label='Services' component={Link} to={`/deals/${dealId}/services`} disabled />
              <Tab label='Strategy' component={Link} to={`/deals/${dealId}/strategy`} disabled />
              {/* )} */}
              <Tab
                disabled={deal && deal.products && Array.isArray(deal.products) && deal.products.length === 0}
                label='Payment Schedule'
                component={Link}
                to={`/deals/${dealId}/paymentschedule`}
              />
              <Tab
                disabled={deal && deal.products && Array.isArray(deal.products) && deal.products.length === 0}
                label='Review Deal'
                component={Link}
                to={`/deals/${dealId}/reviewdeal`}
              />
              <Tab
                disabled={deal && deal.products && Array.isArray(deal.products) && deal.products.length === 0}
                label='Invoicing'
                component={Link}
                to={`/deals/${dealId}/invoicing`}
              />
            </Tabs>
            <Box
              sx={{
                height: 'calc(100vh - 145px)', // Adjust this value based on your header height
                p: `0px !important`,
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </DealDetailsProvider>
      </Box>
    </>
  )
}

export default DealPage

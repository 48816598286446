import { Avatar, Box, Drawer, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { slugify } from '../../../helperFunctions/slugify'
import { formatDayjsDate } from './renderDealsColumns'
import UserImageComponent from '../../Users/UserImageComponent'
import { getDensityStyles, userImageStyles } from '../style'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import RenderApprovers from '../../Approvals/RenderApprovers'
import DoneIcon from '@mui/icons-material/Done'

export const renderApprovalsColumns = (deals, users, theme, density = 'standard') => {
  const densityStyles = getDensityStyles(density)
  const containsOperator = getGridStringOperators().find(op => op.value === 'contains')
  // Custom operator that wraps the 'contains' logic to invert it
  const customNotContainsOperator = {
    ...containsOperator,
    label: 'does not contain',
    value: 'notContains',
    getApplyFilterFn: filterItem => {
      // console.log('[customNotContainsOperator] filterItem:', filterItem)

      // Check if the filter value is defined
      if (filterItem.value === undefined || filterItem.value === null) {
        // console.log('[customNotContainsOperator] Filter value is undefined or null:', filterItem.value)
        return null
      }

      const containsFn = containsOperator.getApplyFilterFn(filterItem)
      if (!containsFn) {
        // console.log('[customNotContainsOperator] No containsFn found for filterItem:', filterItem)
        return null
      }

      // Log the cellValue during the filter function application
      return cellValue => {
        // console.log('[customNotContainsOperator] cellValue:', cellValue)
        const result = !containsFn(cellValue)
        // console.log('[customNotContainsOperator] Result of filter application:', result)
        return result
      }
    },
  }
  return [
    {
      field: 'name',
      headerName: 'Deal Name',
      width: 100,
      flex: 1.75,
      headerAlign: 'left',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const isApproved = params && params.row && params.row.isApproved
        const isRequested = params && params.row && params.row.isRequested
        const dealId = params.row.id
        return (
          <Link
            to={`/deals/${dealId}/reviewdeal`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              backgroundColor: 'transparent',
            }}
          >
            <Tooltip
              title={<Typography sx={{ backgroundColor: 'transparent' }}>{params.value}</Typography>}
              enterDelay={2000}
              sx={{
                backgroundColor: 'transparent', // Transparent background for tooltip
                boxShadow: 'none', // Remove box shadow
                '.MuiTooltip-tooltip': {
                  backgroundColor: 'transparent', // Make tooltip content background transparent
                  boxShadow: 'none', // Remove box shadow around tooltip
                  padding: 0, // Remove padding if you want only the text to be visible
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                {isApproved ? (
                  <Typography
                    sx={{ fontSize: theme.palette.text.size, color: theme.palette.text.secondary, textAlign: 'left' }}
                  >
                    {params.value}
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: theme.palette.text.size, color: theme.palette.text.primary, textAlign: 'left' }}
                  >
                    {params.value}
                  </Typography>
                )}
              </Box>
            </Tooltip>
          </Link>
        )
      },
    },
    // {
    //   field: 'isApproved',
    //   headerName: 'Approved?',
    //   width: 100,
    //   flex: 0.5,
    //   valueGetter: params => {
    //     return params
    //   },
    //   renderCell: params => {
    //     const value =
    //       params && params.value === true ? (
    //         <DoneAllIcon sx={{ color: 'rgb(6, 255, 167)' }} />
    //       ) : (
    //         <DoneAllIcon sx={{ color: 'rgb(60, 57, 57)' }} />
    //       )
    //     return <Box>{value}</Box>
    //   },
    // },
    {
      field: 'isApproved',
      headerName: 'Approved?',
      width: 100,
      flex: 0.5,
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        const value =
          params && params.value === true ? (
            <DoneIcon sx={{ color: 'rgb(6, 255, 167)' }} />
          ) : (
            <DoneIcon sx={{ color: 'rgb(60, 57, 57)' }} />
          )
        return <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>{value}</Box>
      },
    },
    {
      field: 'approvedTime',
      headerName: 'Time Approved',
      width: 100,
      flex: 0.5,
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box>
            {params && params.value ? formatDayjsDate(params && params.value ? params.value : 'No Modified Time') : '—'}
          </Box>
        )
      },
    },
    {
      field: 'approvedBy',
      headerName: 'Approved By',
      width: 100,
      flex: 0.5,
      valueGetter: params => {
        const userId = params ? params : null
        const user = users.find(u => u.id === userId)
        const userName = user ? user.name : ''
        return userName
      },
      renderCell: params => {
        // const userId = params && params.value ? params.value : null
        const userId = params && params.row && params.row.approvedBy ? params.row.approvedBy : null
        const user = users.find(u => u.id === userId)
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <UserImageComponent
                style={userImageStyles}
                imageUrl={user.image_512}
                width={densityStyles.avatarSize}
                height={densityStyles.avatarSize}
              />
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: densityStyles.fontSize,
                }}
              >
                <IconButton disabled>
                  <Avatar />
                </IconButton>
              </Box>
            )}
          </Box>
        )
      },
    },
    // {
    //   field: 'isRequested',
    //   headerName: 'Requested?',
    //   width: 75,
    //   flex: 0.35,
    //   valueGetter: params => {
    //     return params
    //   },
    //   renderCell: params => {
    //     const isApproved = params && params.row ? params.row.isApproved : false
    //     const value =
    //       !isApproved && params && params.value === true ? (
    //         <DoneIcon sx={{ color: 'rgb(251, 193, 30)' }} />
    //       ) : isApproved && params && params.value === true ? (
    //         <DoneIcon sx={{ color: 'rgba(6, 255, 167, 0.5)' }} />
    //       ) : (
    //         <DoneIcon sx={{ color: 'rgb(60, 57, 57)' }} />
    //       )
    //     return (
    //       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>{value}</Box>
    //     )
    //   },
    // },
    {
      field: 'requestedTime',
      headerName: 'Time Requested',
      width: 100,
      flex: 0.5,
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box>
            {params && params.value ? formatDayjsDate(params && params.value ? params.value : 'No Modified Time') : '—'}
          </Box>
        )
      },
    },
    {
      field: 'requestedBy',
      headerName: 'Requested By',
      width: 100,
      flex: 0.5,
      valueGetter: params => {
        const userId = params ? params : null

        const user = users.find(u => u.id === userId)
        return userId ? userId : null
      },
      renderCell: params => {
        const userId = params && params.value ? params.value : null

        const user = users.find(u => u.id === userId)
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <UserImageComponent
                style={userImageStyles}
                imageUrl={user.image_512}
                width={densityStyles.avatarSize}
                height={densityStyles.avatarSize}
              />
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: densityStyles.fontSize,
                }}
              >
                <IconButton disabled>
                  <Avatar />
                </IconButton>
              </Box>
            )}
          </Box>
        )
      },
    },
    // {
    //   field: 'needsApproval',
    //   headerName: 'Needs Approval?',
    //   width: 100,
    //   flex: 0.5,
    //   valueGetter: params => {
    //     return params
    //   },
    //   renderCell: params => {
    //     const value = params && params.value === true ? 'True' : 'False'
    //     return <Box>{value}</Box>
    //   },
    // },
    // {
    //   field: 'lastModified',
    //   headerName: 'Last Modified',
    //   width: 100,
    //   flex: 1,
    //   valueGetter: params => {
    //     if (!params) return null
    //     // Convert date string to a dayjs object
    //     const date = dayjs(params, 'MMMM D, YYYY')
    //     return params ? params : null
    //   },
    //   renderCell: params => {
    //     return <Box> {formatDayjsDate(params && params.value ? params.value : 'No Modified Time')}</Box>
    //   },
    // },
    {
      field: 'type',
      headerName: 'Approvers',
      width: 100,
      flex: 1,
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        const msaTypes = ['MSA', 'MSA - Housing', 'RMSA', 'RMSA - Housing']
        const msaApprovers = ['U015KLM51B6', 'U06LJAMPWJ1', 'U01FK2W9YNS'] // Patric, Thomas, Ryan
        const csaApprovers = ['UPH8S2B9B', 'UMU21GS7Q', 'U03RWJJ4LRJ'] // Phillip, Zach, Brooke

        return (
          <>
            {params && msaTypes.includes(params.value) ? (
              <RenderApprovers approverIds={msaApprovers} densityStyles={densityStyles} />
            ) : (
              <RenderApprovers approverIds={csaApprovers} densityStyles={densityStyles} />
            )}
          </>
        )
      },
      // sortComparator: (date1, date2) => {
      //   // Handle null dates
      //   if (date1 === null) return -1
      //   if (date2 === null) return 1
      //   return date1.getTime() - date2.getTime()
      // },
    },
  ]
}

import React, { useEffect, useState } from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import DealInfoCard from './DealInfoCard'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import RenderContract from './RenderContract'
import { gridStyles } from './style'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import useSaveDeal from '../../api/customHooks/useSaveDeal'
import DetailsLoading from '../Loading/DetailsLoading'
import { useNewContactsById } from '../../api/customHooks/useNewContactsById'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { determineApprovalState } from '../DealApproval/determineApprovalState'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useDeal } from '../../api/aws/useDeal'
import { useQueryClient } from '@tanstack/react-query'

const DealsDetails = () => {
  const { dealId } = useParams()
  const { deal, isDealLoading, isDealError } = useDeal(dealId)
  const theme = useTheme()
  // Access the deal context
  const { newDeal, setNewDeal } = useDealDetailsContext()
  const [isError, setIsError] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isApproving, setIsApproving] = useState(false)
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const { mutate: saveDeal } = useSaveDeal()
  const { showSnackbar } = useSnackbarContext()
  const { mutate: updateItem } = useUpdateItem()
  // Retrieve contacts associated with the deal
  const newContactIds = newDeal && newDeal.contacts && Array.isArray(newDeal.contacts) ? newDeal.contacts : []
  const newContacts = useNewContactsById(newContactIds)
  const queryClient = useQueryClient()
  // Function to update deal data
  const handleUpdate = (fieldName, newValue) => {
    if (newDeal[fieldName] !== newValue) {
      updateItem(
        {
          primaryKey: 'deal_uuid',
          tableName: 'deals',
          itemId: dealId,
          fieldName,
          newValue,
        },
        {
          onSuccess: () => {
            console.log('Update successful')
            showSnackbar('Successfully Updated', 'success')
          },
          onError: err => {
            console.error('Update failed', err)
            showSnackbar('Update Failed', 'error')
          },
        }
      )
    }
  }
  // Save updated deal
  const handleSave = (deal, organization) => {
    console.log('Saving Deal....: ', deal)
    const dealData = {
      ...deal,
      status: !deal.hasProducts ? 'Draft' : deal.hasProducts && deal.status === 'Draft' ? 'Saved' : deal.status,
      stage: !deal.hasProducts ? 'Discovery' : deal.hasProducts && deal.stage === 'Discovery' ? 'Proposal' : deal.stage,
      lastModified: new Date(),
    }
    console.log('Saving new deal data: ', dealData)
    saveDeal(
      {
        dealId: deal.deal_uuid,
        dealData: dealData,
      },
      {
        onSuccess: () => {
          setIsEditMode(false)
          setIsOpen(false)
          //  queryClient.invalidateQueries([`/aws/deals`, data.itemId])
          //  console.log('Invalidated Deal: ')
        },
        onError: () => {
          setIsEditMode(false)
          setIsOpen(false)
          setIsError(true)
        },
      }
    )
  }

  const handleEdit = () => {
    setIsEditMode(true)
    setIsOpen(true)
  }

  const handleCloseEditMode = () => {
    setIsEditMode(false)
    setIsOpen(false)
  }
  if (isDealLoading) {
    return <DetailsLoading />
  }
  return (
    <>
      <Grid container spacing={2} justifyContent='center' sx={gridStyles(theme)}>
        {/* {detailsLoading ? (
          <DetailsLoading />
        ) : ( */}
        <>
          {!isEditMode ? (
            <>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <DealInfoCard
                  key={dealId}
                  deal={deal} // Use the deal from the context
                  onEdit={handleEdit}
                  newContacts={newContacts}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        padding: 2,
                        backgroundColor: gridBgColor,
                        height: 200,
                        borderRadius: '25px',
                        width: '100%',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        padding: 2,
                        backgroundColor: gridBgColor,
                        height: 130,
                        borderRadius: '25px',
                        width: '100%',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={8} sm={10} md={12} lg={12}>
                <Grid item xs={8} sm={12}>
                  <Box
                    sx={{
                      width: '100%',
                      maxHeight: 'calc(100vh - 145px)', // Adjust the height to account for the header and fit within the viewport
                      overflowY: 'auto', // Enable vertical scrolling
                      backgroundColor: gridBgColor,
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor:
                          theme.palette.mode === 'dark' ? 'rgba(167,51,51,0.65)' : 'rgba(167,51,51,0.65)',
                        borderRadius: '6px',
                      },
                      // padding: 4,
                      borderRadius: '25px',
                    }}
                  >
                    <RenderContract
                      dealId={dealId}
                      onSaveDeal={handleSave}
                      onClose={handleCloseEditMode}
                      open={isOpen}
                      onUpdate={handleUpdate}
                      isEditMode={isEditMode}
                    />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </>
        {/* )} */}
      </Grid>
    </>
  )
}

export default DealsDetails

import React, { useContext, useEffect, useState } from 'react'
import { Box, Typography, Grid, Button, useTheme, CircularProgress, Tooltip } from '@mui/material'
import { InfoBox, InfoRow, InfoLabel, InfoValueWrapper, CustomInfoBox } from './style'
import { useUsersById } from '../../api/customHooks/useUsersById'
import AssignOwnerAutocomplete from '../EditModeComponents/AssignOwnerAutocomplete'
import AssignCompaniesAutocomplete from '../EditModeComponents/AssignCompaniesAutocomplete'
import { CreateButton, SaveButton } from '../ContractDialog/style'
import WarningIcon from '@mui/icons-material/Warning'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import { useDeals } from '../../api/aws/useDeals'
import { useParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import DetailsLoading from '../Loading/DetailsLoading'
import DealStatusSelect from '../DealStatusSelect/DealStatusSelect'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import ApprovalRequestButton from '../ui/approval-request-button'
import { useActiveUser } from '../../api/slack/useActiveUser'

const EditDealInfo = ({
  selectedDeal,
  setSelectedDeal,
  organization,
  contractedBy,
  onUpdate,
  isCreating,
  onClose,
  onSave,
  isValid,
  onCreate,
  validationErrors,
}) => {
  // const dealId = selectedDeal ? selectedDeal.id : null
  const theme = useTheme()
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const { approvalState, handleRequestApproval } = useDealDetailsContext()
  const [newDetails, setNewDetails] = useState(selectedDeal)
  const [initialDetails, setInitialDetails] = useState(selectedDeal)
  const [isError, setIsError] = useState(false)
  const verticalsApprovalNotRequired = ['Student', 'Multifamily']
  const [isRequesting, setIsRequesting] = useState(false)
  const msaApprovers = ['U015KLM51B6', 'U06LJAMPWJ1', 'UHKAA49CJ', 'U062JDP8R5X', 'U01FK2W9YNS']
  const { activeUser } = useActiveUser()
  const [canCreateContract, setCanCreateContract] = useState(
    organization && Array.isArray(organization.vertical)
      ? !verticalsApprovalNotRequired.includes(organization.vertical)
      : false
  )
  const csaTypes = ['CSA', 'RCSA']

  const handleCompanyChange = (context, value) => {
    setNewDetails(prev => ({
      ...prev,
      [context]: value,
    }))
  }
  const handleObjectBlur = (context, newValue) => {
    if (JSON.stringify(initialDetails[context]) !== JSON.stringify(newValue)) {
      onUpdate(context, newValue)
      setInitialDetails(prev => ({
        ...prev,
        [context]: newValue,
      }))
    }
  }

  const handleApprovalRequest = () => {
    console.log('Approval Requested!!!!!!')
    handleRequestApproval(selectedDeal, activeUser, msaApprovers)
  }
  // useEffect(() => {
  //   if (approvalState) {
  //     console.log('[EditDealInfo] Approval State: ', approvalState)
  //   }
  // }, [approvalState])
  return (
    <>
      <CustomInfoBox sx={{ backgroundColor: gridBgColor, position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
            }}
            variant='h6'
            gutterBottom
          >
            Information
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {isCreating && (
              <CircularProgress
                sx={{
                  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
                }}
              />
            )}
            {isError && <Typography color='error'>Error creating deal</Typography>}
            <Button onClick={onClose} color='primary' variant='outlined'>
              Cancel
            </Button>
            <SaveButton onClick={onSave} color='secondary' variant='contained'>
              Save
            </SaveButton>
            {selectedDeal && !selectedDeal.noSignature && (
              <Tooltip
                title={
                  // Only show the tooltip if the deal needs approval and `canCreateContract` is false
                  selectedDeal.needsApproval && !selectedDeal.isApproved && !selectedDeal.isCompleted
                    ? 'Deal needs approval before creating contract'
                    : ''
                }
              >
                <Box>
                  {selectedDeal &&
                  selectedDeal.needsApproval &&
                  !selectedDeal.isApproved &&
                  !selectedDeal.isCompleted &&
                  !['IO'].includes(selectedDeal.type) ? (
                    <ApprovalRequestButton
                      isRequested={selectedDeal.isRequested}
                      isRequesting={isRequesting}
                      onRequest={handleApprovalRequest}
                      startIcon={true}
                    />
                  ) : (
                    <CreateButton
                      onClick={onCreate}
                      color='primary'
                      variant='contained'
                      startIcon={<PandaDocImageComponent size={30} />}
                    >
                      Create Contract
                    </CreateButton>
                  )}
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={selectedDeal && (selectedDeal.noSignature || selectedDeal.isCompleted) ? 3 : 4}>
            <InfoRow>
              <InfoLabel>Owner</InfoLabel>
              <InfoValueWrapper>
                <AssignOwnerAutocomplete
                  userId={(selectedDeal.owner && selectedDeal.owner[0]) || ''}
                  label=''
                  onChange={handleCompanyChange}
                  context={'owner'}
                  onBlur={handleObjectBlur}
                  multiple={false}
                />
              </InfoValueWrapper>
            </InfoRow>
          </Grid>
          <Grid item xs={12} sm={selectedDeal && (selectedDeal.noSignature || selectedDeal.isCompleted) ? 3 : 4}>
            <InfoRow>
              <InfoLabel>Organization</InfoLabel>
              <InfoValueWrapper>
                <AssignCompaniesAutocomplete
                  label=''
                  value={organization ? [organization] : []}
                  onChange={handleCompanyChange}
                  context={'organizations'}
                  onBlur={handleObjectBlur}
                  disabled={true}
                />
              </InfoValueWrapper>
            </InfoRow>
          </Grid>
          <Grid item xs={12} sm={selectedDeal && (selectedDeal.noSignature || selectedDeal.isCompleted) ? 3 : 4}>
            <InfoRow>
              <InfoLabel>Contracted By</InfoLabel>
              <InfoValueWrapper>
                <AssignCompaniesAutocomplete
                  label=''
                  value={contractedBy ? [contractedBy] : []}
                  onChange={handleCompanyChange}
                  context={'organizations'}
                  onBlur={handleObjectBlur}
                  disabled={true}
                />
              </InfoValueWrapper>
            </InfoRow>
          </Grid>
          {selectedDeal && (selectedDeal.noSignature || selectedDeal.isCompleted) && (
            <Grid item xs={12} sm={selectedDeal && (selectedDeal.noSignature || selectedDeal.isCompleted) ? 3 : 4}>
              <InfoRow>
                <InfoLabel sx={{ pt: 0.8 }}>Deal Status</InfoLabel>
                <InfoValueWrapper>
                  <DealStatusSelect
                    newDeal={selectedDeal}
                    setNewDeal={setSelectedDeal}
                    error={validationErrors.dealStatus}
                    helperText={validationErrors.dealStatus && 'Deal Status is required'}
                    onChange={handleCompanyChange}
                  />
                </InfoValueWrapper>
              </InfoRow>
            </Grid>
          )}
        </Grid>
      </CustomInfoBox>
    </>
  )
}

export default EditDealInfo

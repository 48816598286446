import React, { useEffect, useState } from 'react'
import { Button, Box, Typography, Divider, useTheme, CircularProgress, Avatar, Tooltip, Stack } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { DealDetailsStatusChip, GridStatusChip } from '../../style/styleElements'
import { detailTextStyle, detailHeaderStyle, detailNameStyle, stringAvatar } from './style'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useUser } from '../../api/aws/useUser'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import UserImageComponent from '../Users/UserImageComponent'
import RenderVertical from '../Organizations/RenderVertical'
import ApproveButton from '../ui/approve-button'
import ApprovalRequestButton from '../ui/approval-request-button'
import { useNotifyMessage } from '../../api/slack/useNotifyMessage'
import { useQueryClient } from '@tanstack/react-query'
import { createApprovedMessage, createRequestMessage } from '../Notifications/createNotifyPayload'
import CreativeApproveButton from '../ui/creative-approve-button'
import { determineApprovalState, handleEditDealState } from '../DealApproval/determineApprovalState'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { useUsers } from '../../api/aws/useUsers'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider' // Import the context
import { useParams } from 'react-router-dom'
import { useDeal } from '../../api/aws/useDeal'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import GroboticIcon from '../Icons/GroboticIcon'

const RenderPandaContractStatus = ({ status }) => {
  const pandaStatus = ['Completed', 'Viewed', 'Contract Approved', 'Created', 'Sent']
  return (
    <Stack direction={'row'} display={'flex'} alignItems={'center'} gap={1}>
      {status && pandaStatus.includes(status) ? (
        <>
          <PandaDocImageComponent size={25} />
          <Typography>Contract Status</Typography>
        </>
      ) : (
        <>
          <GroboticIcon height={35} width={35} />
          <Typography>Deal Status</Typography>
        </>
      )}
    </Stack>
  )
}

const DealsDetailsHeader = () => {
  const theme = useTheme()
  const { showSnackbar } = useSnackbarContext()
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  // Inside DealsDetailsHeader
  const { dealId } = useParams()
  // const { deal: newDeal, isDealLoading, isDealError } = useDeal(dealId)
  ////////////////////////////////////////////////////////////////////////////////////
  // Use the newDeal from the context instead of fetching it with useDeal
  const { newDeal, setNewDeal, originalApprovalStatus } = useDealDetailsContext()
  const [isApproving, setIsApproving] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const { activeUser } = useActiveUser()
  const { users } = useUsers('Deals Details Header')
  const { organization, isOrganizationLoading } = useOrganizationById(
    newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : null
  )
  const { data: approvedByUser, isLoading: approvedByUserLoading } = useUser(
    newDeal && newDeal.approvedBy ? newDeal.approvedBy : ''
  )
  const msaApprovers = ['U015KLM51B6', 'U06LJAMPWJ1', 'UHKAA49CJ', 'U062JDP8R5X', 'U01FK2W9YNS']
  const csaApprovers = ['UPH8S2B9B', 'UMU21GS7Q', 'THWLAUG4V', 'U03RWJJ4LRJ', 'U062JDP8R5X', 'UHKAA49CJ']
  const csaTypes = ['CSA', 'RCSA']
  const msaTypes = ['MSA', 'RMSA', 'RMSA - Housing', 'MSA - Housing']
  const queryClient = useQueryClient()
  const { mutate: updateItems } = useUpdateItems()
  const { mutate: updateDeal } = useUpdateDeal()
  const { mutate: sendNotification } = useNotifyMessage()
  const { data: requestedBy } = useUser(newDeal && newDeal.requestedBy ? newDeal.requestedBy : '')
  // const [approvalState, setApprovalState] = useState(handleEditDealState(newDeal, organization))

  const handleUpdateDeal = async (dealId, dealData) => {
    try {
      updateDeal(
        { dealId, dealData },
        {
          onSuccess: () => {
            setIsApproving(false)
            showSnackbar('Successfully Updated', 'success')
            queryClient.invalidateQueries(['deals'])
          },
          onError: err => {
            setIsApproving(false)
            showSnackbar('Update Failed', 'error')
          },
        }
      )
    } catch (error) {
      console.error('Error during update:', error)
    }
  }

  const approveDeal = async (payload, dealData) => {
    try {
      sendNotification(payload, {
        onSuccess: () => {
          showSnackbar('Deal Approved successfully', 'success')
          handleUpdateDeal(newDeal.deal_uuid, dealData)
        },
        onError: error => {
          console.log(`Error creating task or sending thread: ${error}`)
        },
      })
    } catch (error) {
      console.error('Error approving newDeal:', error)
    }
  }

  const handleApproveDeal = async () => {
    setIsApproving(true)
    const payload = createApprovedMessage(newDeal, activeUser.user.id)
    const dealData = {
      isApproved: true,
      approvedTime: new Date(),
      approvedBy: activeUser.user.id,
      status: 'Strategy Approved',
      stage: 'Proposal',
    }

    try {
      await approveDeal(payload, dealData)
    } catch (error) {
      console.log('Error during newDeal approval:', error)
    }
  }

  // const requestApproval = async (payload, dealData) => {
  //   try {
  //     sendNotification(payload, {
  //       onSuccess: () => {
  //         showSnackbar('Request successfully sent', 'success')
  //         handleUpdateDeal(newDeal.deal_uuid, dealData)
  //       },
  //       onError: error => {
  //         console.log(`Error creating task or sending thread: ${error}`)
  //       },
  //     })
  //   } catch (error) {
  //     console.error('Error requesting approval:', error)
  //   }
  // }

  // const handleRequestApproval = async () => {
  //   const payload = createRequestMessage(newDeal, activeUser.user.id, msaApprovers)
  //   const dealData = {
  //     isRequested: true,
  //     requestedBy: activeUser.user.id,
  //     status: 'Approval Requested',
  //   }

  //   try {
  //     await requestApproval(payload, dealData)
  //   } catch (error) {
  //     console.log('Error during approval request:', error)
  //   }
  // }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 3,
        position: 'sticky',
        zIndex: 901,
        marginLeft: 5,
        mr: 1,
        p: 0.5,
      }}
    >
      {newDeal && organization && (
        <>
          {organization && organization.photoUrl ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
              <Avatar src={organization.photoUrl} sx={{ width: 56, height: 56 }} />
              <Typography sx={{ fontWeight: 'bold' }}>{organization.name}</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                gap: 4,
              }}
            >
              <Avatar
                {...stringAvatar(organization.name)}
                sx={{ backgroundColor: 'rgb(251, 193, 30)', width: 48, height: 48 }}
                alt={organization.name}
              />
            </Box>
          )}
          <Typography sx={detailNameStyle(theme)}>{newDeal ? newDeal.name : 'No Name Assigned'}</Typography>
          <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
          {/* <AttachMoneyIcon sx={{ color: '#fbc11e' }} />
          <Typography sx={detailHeaderStyle(theme)}>{'Deal Details'}</Typography>
          <Divider orientation='vertical' flexItem sx={{ mx: 2 }} /> */}
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
            {/* <Typography sx={{ color: theme.palette.text.secondary, fontSize: 12, textAlign: 'center' }}>
              Vertical
            </Typography> */}
            {organization && organization.vertical && Array.isArray(organization.vertical) ? (
              organization.vertical.map((res, index) => (
                <RenderVertical key={`vertical-organization.id-${index}`} vertical={res} />
              ))
            ) : (
              <RenderVertical vertical={organization && organization.vertical ? organization.vertical : []} />
            )}
          </Box>
          <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2, alignItems: 'center' }}>
            {/* <Typography sx={{ color: theme.palette.text.secondary, fontSize: 12, textAlign: 'center' }}>
              Status
            </Typography> */}
            <Tooltip title={<RenderPandaContractStatus status={newDeal ? newDeal.status : 'No Status Assigned'} />}>
              <DealDetailsStatusChip
                label={newDeal ? newDeal.status : 'No Status Assigned'}
                theme={theme}
                status={newDeal.status}
              />
            </Tooltip>
          </Box>
        </>
      )}
      <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
      {newDeal && newDeal.products && Array.isArray(newDeal.products) && newDeal.products.length > 0 && (
        <>
          {/* {newDeal &&
            newDeal.needsApproval &&
            !newDeal.isRequested &&
            !newDeal.noSignature &&
            csaTypes.includes(newDeal.type) && (
              <ApprovalRequestButton
                isRequested={newDeal.isRequested}
                isRequesting={isRequesting}
                onRequest={handleRequestApproval}
              />
            )}
          {newDeal &&
            newDeal.needsApproval &&
            !newDeal.isRequested &&
            !newDeal.noSignature &&
            msaTypes.includes(newDeal.type) && (
              <ApprovalRequestButton
                isRequested={newDeal.isRequested}
                isRequesting={isRequesting}
                onRequest={handleRequestApproval}
              />
            )}
          {newDeal &&
            newDeal.needsApproval &&
            newDeal.isRequested &&
            !newDeal.isApproved &&
            !newDeal.noSignature &&
            msaTypes.includes(newDeal.type) &&
            msaApprovers.includes(activeUser.user.id) && (
              <ApproveButton
                needsApproval={newDeal.needsApproval}
                isApproving={isApproving}
                isApproved={newDeal.isApproved}
                onApprove={handleApproveDeal}
              />
            )}
          {newDeal &&
            newDeal.needsApproval &&
            newDeal.isRequested &&
            !newDeal.isApproved &&
            !newDeal.isCompleted &&
            !newDeal.isCreated &&
            !newDeal.noSignature &&
            csaTypes.includes(newDeal.type) &&
            csaApprovers.includes(activeUser.user.id) && (
              <CreativeApproveButton
                needsApproval={newDeal.needsApproval}
                isApproving={isApproving}
                isApproved={newDeal.isApproved}
                onApprove={handleApproveDeal}
              />
            )} */}
          {newDeal &&
            newDeal.needsApproval &&
            newDeal.isRequested &&
            !newDeal.isApproved &&
            !newDeal.noSignature &&
            msaTypes.includes(newDeal.type) &&
            msaApprovers.includes(activeUser.user.id) && (
              <ApproveButton
                needsApproval={newDeal.needsApproval}
                isApproving={isApproving}
                isApproved={newDeal.isApproved}
                onApprove={handleApproveDeal}
              />
            )}
          {newDeal &&
            newDeal.needsApproval &&
            newDeal.isRequested &&
            !newDeal.isApproved &&
            !newDeal.isCompleted &&
            !newDeal.isCreated &&
            !newDeal.noSignature &&
            csaTypes.includes(newDeal.type) &&
            csaApprovers.includes(activeUser.user.id) && (
              <CreativeApproveButton
                needsApproval={newDeal.needsApproval}
                isApproving={isApproving}
                isApproved={newDeal.isApproved}
                onApprove={handleApproveDeal}
              />
            )}
          {newDeal && newDeal.needsApproval && newDeal.isRequested && !newDeal.isApproved && (
            <>
              <VerifiedUserIcon sx={{ color: 'crimson' }} />
              {approvedByUserLoading && <CircularProgress />}
              <Typography>Requested By:</Typography>
              <UserImageComponent imageUrl={requestedBy ? requestedBy.image_512 : ''} />
            </>
          )}
          {newDeal && newDeal.needsApproval && newDeal.isApproved && !newDeal.noSignature && (
            <>
              <VerifiedUserIcon sx={{ color: 'green' }} />
              {approvedByUserLoading && <CircularProgress />}
              <Typography>Approved By:</Typography>
              <UserImageComponent imageUrl={approvedByUser ? approvedByUser.image_512 : ''} />
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default DealsDetailsHeader

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Box, useTheme, Button, Typography, Tooltip, Chip } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDeleteOrganization } from '../../api/aws/useDeleteOrganization'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { renderOrganizationColumns } from '../DataGrid/createColumns/renderOrganizationColumns'
import { filterPanelProps, gridContainerStyles } from './style'
import { useUpdateMassItems } from '../../api/aws/useUpdateMassItems'
import LoadingOverlay from '../Loading/LoadingOverlay'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import { useUsers } from '../../api/aws/useUsers'
import { useContacts } from '../../api/aws/useContacts'
import { renderApprovalsColumns } from '../DataGrid/createColumns/renderApprovalsColumns'
import { useDeals } from '../../api/aws/useDeals'
import CustomApprovalsToolbar from './CustomApprovalsToolbar'

export const initialSortModel = [
  { field: 'isApproved', sort: 'asc' },
  { field: 'requestedTime', sort: 'desc' },
  { field: 'approvedTime', sort: 'desc' },
]
export const initialVisibilityState = {}

const ApprovalsPage = () => {
  const theme = useTheme()
  const filterMenuBorder =
    theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : '1px solid rgba(0,0,0,0.5)'
  const { showSnackbar } = useSnackbarContext()
  const { deals: rawDeals, isDealsLoading, isDealsError } = useDeals()
  const [deals, setDeals] = useState([])
  const { users, isUsersLoading, isUsersError } = useUsers()
  const [progress, setProgress] = useState(0)
  const [isUpdating, setIsUpdating] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  // Load filters from localStorage or use an empty default filter model
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilters = localStorage.getItem('approvalsFilterModel')
    return savedFilters
      ? JSON.parse(savedFilters)
      : { items: [{ field: 'isApproved', operator: 'contains', value: 'False' }], quickFilterValues: [] }
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem('approvalsVisibilityModel')
    return savedVisibility ? JSON.parse(savedVisibility) : {}
  })
  const [sortModel, setSortModel] = useState(() => {
    const savedSortModel = localStorage.getItem('approvalsSortModel')
    return savedSortModel ? JSON.parse(savedSortModel) : initialSortModel
  })
  // Function to check if the sort model has changed
  const isSortModelChanged = sortModel.some((sort, index) => {
    const initialSort = initialSortModel[index]
    return sort.field !== initialSort?.field || sort.sort !== initialSort?.sort
  })
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations()
  const [density, setDensity] = useState('standard')
  const { mutate: deleteOrganization } = useDeleteOrganization()
  const { contacts, isContactsLoading, isContactsError } = useContacts()
  const [selectionModel, setSelectionModel] = useState([])
  const [massUpdateData, setMassUpdateData] = useState({
    vertical: [],
    type: '',
    status: '',
  })
  const [rowCount, setRowCount] = useState(organizations.length)
  const apiRef = useGridApiRef()
  // const quickFilterRef = useRef(null)
  const { mutate: updateItems } = useUpdateItems()
  const { mutate: updateMassItems } = useUpdateMassItems(['organizations'])
  const { mutate: deleteItem } = useDeleteMutation()

  useEffect(() => {
    if (filterModel) {
      // Save the filter model to localStorage when it changes
      localStorage.setItem('approvalsFilterModel', JSON.stringify(filterModel))
    }
  }, [filterModel])
  useEffect(() => {
    if (selectionModel.length === 1) {
      const newOrganization = organizations.find(org => org.id === selectionModel[0])
      setSelectedOrganization(newOrganization)
      setMassUpdateData({ vertical: [], type: '' })
    } else {
      setSelectedOrganization(null)
    }
  }, [selectionModel, organizations])

  // UseMemo to derive filtered deals
  const processedDeals = useMemo(() => {
    if (!rawDeals) return []
    return rawDeals.filter(
      deal => deal && deal.needsApproval && deal.isRequested && deal.status !== 'Completed Internally'
    )
  }, [rawDeals])

  const isLoading = isOrganizationsLoading || isUsersLoading || isContactsLoading

  if (isLoading) {
    return <DetailsLoading />
  }

  const columns = renderApprovalsColumns(deals, users, theme, density, columnVisibilityModel)

  const handleFilterModelChange = model => {
    setFilterModel(model)
  }
  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }
  const handleDensityChange = newDensity => {
    setDensity(newDensity)
  }

  const handleClearSorting = e => {
    e.preventDefault()
    setSortModel([...initialSortModel]) // Reset the sort model
    localStorage.setItem('approvalsSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
  }
  const handleSetOpenApprovals = e => {
    e.preventDefault()
    setFilterModel({ items: [{ field: 'isApproved', operator: 'contains', value: 'False' }], quickFilterValues: [] })
    setSortModel([...initialSortModel]) // Reset the sort model
    localStorage.setItem('approvalsSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
  }
  const handleClearVisibility = e => {
    e.preventDefault()
    const initialVisibilityState = {}
    setColumnVisibilityModel(initialVisibilityState)
    localStorage.setItem('approvalsVisibilityModel', JSON.stringify(initialVisibilityState))
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          ...gridContainerStyles,
          height: 'calc(100vh - 20px)',
        }}
      >
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [],
            },
            filter: {
              filterModel: filterModel,
            },
            columns: {
              columnVisibilityModel: {},
            },
          }}
          rows={processedDeals}
          columns={columns}
          rowHeight={density === 'compact' ? 40 : density === 'comfortable' ? 60 : 52}
          getRowId={row => row.deal_uuid}
          checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => {
            setColumnVisibilityModel(newModel)
            localStorage.setItem('approvalsVisibilityModel', JSON.stringify(newModel))
          }}
          sortModel={sortModel}
          onSortModelChange={newModel => {
            setSortModel(newModel)
            localStorage.setItem('approvalsSortModel', JSON.stringify(newModel))
          }}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          apiRef={apiRef}
          slots={{
            toolbar: CustomApprovalsToolbar,
            // footer: OrganizationsGridFooter,
          }}
          slotProps={{
            toolbar: {
              filterModel: filterModel,
              setFilterModel: setFilterModel,
              visibilityModel: columnVisibilityModel,
              sortModel: sortModel,
              isSortModelChanged: isSortModelChanged,
              onOpenApprovals: handleSetOpenApprovals,
              onClearSorting: handleClearSorting,
              onClearVisibility: handleClearVisibility,
            },
            // footer: {
            //   context: 'approvals',
            //   massUpdateData: massUpdateData,
            //   selectionModel: selectionModel,
            //   rowCount,
            //   onChange: handleMassUpdateChange,
            //   onUpdate: handleMassUpdate,
            //   organizations: organizations,
            //   anchorEl: anchorEl,
            //   handleDeleteClick: handleDeleteClick,
            //   handleConfirmDelete: handleConfirmDelete,
            //   handleClosePopover: handleClosePopover,
            //   selectedData: selectedOrganization,
            //   setSelectedData: setSelectedOrganization,
            //   setSelectionModel: setSelectionModel,
            //   apiRef: apiRef,
            // },
            panel: {
              sx: {
                border: filterMenuBorder,
                borderRadius: '4px',
              },
            },
            filterPanel: {
              sx: {
                ...filterPanelProps,
              },
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          onDensityChange={handleDensityChange}
          // headerFilters
          loading={isLoading}
        />
      </Box>
      <LoadingOverlay open={isUpdating} message={'Updating Organizations'} />
    </>
  )
}

export default ApprovalsPage

import React, { useEffect, useState } from 'react'
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  TableCell,
  IconButton,
  useTheme,
  Tooltip,
  TableRow,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import CustomProductsAutocomplete from '../CustomProductsAutocomplete/CustomProductsAutocomplete'
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CustomOptionSelect from '../Reusable/CustomOptionSelect'
import { usePandaContext } from '../../context/PandaProvider/PandaProvider'
import DeleteIcon from '@mui/icons-material/Delete'
import PaidSelect from './PaidSelect'
import EditIcon from '@mui/icons-material/Edit'
import RichTextField from './MuiRichTextField'
import RichTextTooltip from './RichTextTooltip'

const productStatusOptions = [
  { label: 'Pending', color: 'gray' },
  { label: 'Active', color: 'rgb(152, 214, 129)' },
  { label: 'Monthly', color: 'rgb(1, 142, 242)' },
  { label: 'Expired', color: 'rgb(235, 77, 77)' },
  { label: 'Cancelled', color: 'rgb(0,0,0)' },
]
const csaTypes = ['CSA', 'RCSA']
const paidProductsList = [
  'Meta Advertising',
  'Linkedin Advertising',
  'Paid Search Advertising',
  'Google Display Advertising',
  'Spotify Advertising',
  'Youtube Advertising',
  'TikTok Advertising',
]

const numberInputProps = column => {
  const marginProps = {
    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
  }
  const numberProps = {
    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
  }
  const qtyProps = {
    startAdornment: <InputAdornment position='start'></InputAdornment>,
  }
  return column.label === 'Margin' ? marginProps : column.label === 'Qty' ? qtyProps : numberProps
}

const renderTextField = (cellValue, handleProductChange, column, index, validationErrors) => {
  return (
    <>
      {column.id !== 'addtlDescriptor' ? (
        <TextField
          type='text'
          fullWidth
          variant='outlined'
          value={cellValue || ''}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          sx={{ width: column.width }}
        />
      ) : (
        <TextField
          type='text'
          fullWidth
          placeholder='additional descriptor'
          variant='outlined'
          value={cellValue || ''}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          sx={{ width: column.width }}
        />
      )}
    </>
  )
}

const renderNumberField = (cellValue, handleProductChange, column, index, validationErrors) => {
  const handleBlur = e => {
    if (e.target.value === '') {
      handleProductChange(index, column.id, 0)
    }
  }
  return (
    <>
      {column.label === 'Total' || column.label === 'Gross Profit' ? (
        <TextField
          type='text'
          label={column.label}
          fullWidth
          variant='outlined'
          value={cellValue === '' ? '' : parseFloat(cellValue).toFixed(2)}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          onBlur={handleBlur}
          sx={{ width: 'auto', minWidth: 110 }}
          InputProps={{
            readOnly: true,
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      ) : column.label === 'Media Spend' ? (
        <TextField
          type='text'
          label={column.label}
          fullWidth
          variant='outlined'
          value={cellValue === '' ? '' : parseFloat(cellValue).toFixed(2)}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          onBlur={handleBlur}
          sx={{ width: 'auto', minWidth: 110 }}
          InputProps={{
            readOnly: true,
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
        />
      ) : column.label === 'Margin' ? (
        <TextField
          type='text'
          fullWidth
          label={column.label}
          variant='outlined'
          value={cellValue === '' ? '' : parseFloat(cellValue)}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          onBlur={handleBlur}
          sx={{ width: 'auto', minWidth: 85 }}
          InputProps={numberInputProps(column)}
          error={validationErrors.margin}
        />
      ) : column.label === 'Price' ? (
        <TextField
          type='text'
          fullWidth
          label={column.label}
          variant='outlined'
          value={cellValue === '' ? '' : parseFloat(cellValue)}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          onBlur={handleBlur}
          sx={{ width: 'auto', minWidth: 85 }}
          InputProps={numberInputProps(column)}
          error={validationErrors.price}
        />
      ) : column.label === 'Qty' ? (
        <TextField
          type='text'
          fullWidth
          label={column.label}
          variant='outlined'
          value={cellValue === '' ? '' : parseFloat(cellValue)}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          onBlur={handleBlur}
          sx={{ width: 'auto', minWidth: 50 }}
          disabled
          InputProps={numberInputProps(column)}
          error={validationErrors.price}
        />
      ) : (
        <TextField
          type='text'
          fullWidth
          label={column.label}
          variant='outlined'
          value={cellValue === '' ? '' : parseFloat(cellValue)}
          onChange={e => handleProductChange(index, column.id, e.target.value)}
          onBlur={handleBlur}
          sx={{ width: 'auto', minWidth: 85 }}
          InputProps={numberInputProps(column)}
          error={validationErrors}
        />
      )}
    </>
  )
}

const renderDateRangePicker = (cellValue, handleProductChange, index, validationErrors) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DateRangePicker
          startText='Start Date'
          endText='End Date'
          value={cellValue || [null, null]}
          onChange={newValue => handleProductChange(index, 'dateRange', newValue)}
          slotProps={{
            textField: {
              sx: { fontSize: '12px', minWidth: '150px' },
              InputLabelProps: { style: { fontSize: '14px' } },
            },
          }}
          sx={{
            width: '100%',
            '& .MuiMultiInputDateRangeField-separator': {
              display: 'none',
              marginLeft: 0,
              marginRight: 0,
            },
          }}
        />
      </Box>
    </LocalizationProvider>
  )
}

const renderSelect = (cellValue, handleProductChange, column, index, validationErrors) => (
  <CustomOptionSelect
    stateKey='status'
    value={cellValue || productStatusOptions[0].label}
    options={productStatusOptions}
    displayLabel='Status'
    setterFn={newValue => handleProductChange(index, column.id, newValue)}
  />
)
const renderPaidSelect = (cellValue, handleProductChange, column, index, isProductPaid) => {
  const displayLabel = column && column.id === 'payer' ? 'Payer' : 'Account Used'
  const isDisabled = column && column.id === 'payer' ? !isProductPaid : !isProductPaid
  const defaultValue = column.id === 'payer' ? 'GRO' : 'GRO'
  return (
    <PaidSelect
      context={column.id}
      displayLabel={displayLabel}
      value={isDisabled ? defaultValue : cellValue || ''}
      setterFn={newValue => handleProductChange(index, column.id, newValue)}
      disabled={isDisabled}
    />
  )
}

const RenderCell = ({ row, column, index, handleProductChange, validationErrors, handleRemoveRow }) => {
  const theme = useTheme()
  const { products } = usePandaContext()

  const sortedProducts = products
    ? products.sort((a, b) => {
        const categoryA = a.category_name || '' // Assuming you have subCategory
        const categoryB = b.category_name || ''
        const categoryComparison = categoryA.localeCompare(categoryB)
        if (categoryComparison !== 0) {
          return categoryComparison
        }
        return a.title.localeCompare(b.title)
      })
    : []

  const [anchorEl, setAnchorEl] = useState(null)
  const [description, setDescription] = useState(row.description) // Initialize with current description
  const [isOpen, setIsOpen] = useState(false)
  const descriptionBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42)' : 'rgba(255,255,255,1)'

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
    setDescription(row.description) // Ensure the current description is set when opening the popover
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleSaveDescription = () => {
    handleProductChange(index, 'description', description)
    handleClose()
  }

  const open = Boolean(anchorEl)

  const handleProductSelect = (index, selectedProduct) => {
    if (selectedProduct === null) {
      handleProductChange(index, 'selectedProduct', null, {
        description: '',
        margin: '0',
        productName: '',
        price: '0.00',
      })
    } else {
      const updates = {
        description: selectedProduct.description,
        margin: selectedProduct.custom_fields?.find(f => f.name === 'Margin %')?.value || selectedProduct.margin || '0',
        productName: selectedProduct.title,
        price: selectedProduct.price || '0.00',
      }
      // Add this logic to handle specific product titles
      if (paidProductsList.includes(selectedProduct.title)) {
        updates.payer = 'GRO'
        updates.accountUsed = 'GRO'
      }
      handleProductChange(index, 'selectedProduct', selectedProduct, updates)
    }
  }
  const handleClick = event => {
    event.stopPropagation() // Prevent event bubbling that causes popover to close
  }

  const handleOpen = () => {
    setDescription(row.description)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }
  const cellValue = row[column.id]
  const startDate = row.startDate
  const endDate = row.endDate
  const hasError = validationErrors.products && !row.productName

  // Check if the product is in the paidProductsList
  const isProductPaid = paidProductsList.includes(row.productName)

  switch (column.type) {
    case 'text':
      return renderTextField(cellValue, handleProductChange, column, index, validationErrors)
    case 'number':
      return renderNumberField(cellValue, handleProductChange, column, index, validationErrors)
    case 'dateRange':
      return renderDateRangePicker([startDate, endDate], handleProductChange, index, validationErrors)
    case 'select':
      return renderSelect(cellValue, handleProductChange, column, index, validationErrors)
    case 'popover':
      return (
        <>
          <RichTextTooltip content={row.description || 'No description'} onClick={handleOpen} />
          <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth='sm'
            disableEnforceFocus
            disableAutoFocus
            disableRestoreFocus
          >
            <DialogTitle>Edit Description</DialogTitle>
            <DialogContent>
              <Box sx={{ width: '100%', margin: 0 }}>
                <RichTextField content={description || '<p></p>'} onContentChange={setDescription} onBlur={() => {}} />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSaveDescription} color='primary'>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )
    case 'paidSelect':
      return renderPaidSelect(cellValue, handleProductChange, column, index, isProductPaid)
    case 'autocomplete':
      return (
        <CustomProductsAutocomplete
          products={sortedProducts}
          value={
            row.selectedProduct ? row.selectedProduct : products.find(product => product.title === row.productName)
          }
          onChange={newValue => handleProductSelect(index, newValue)}
          label='Select Service'
          error={hasError}
        />
      )
    case 'component':
      return (
        <IconButton onClick={() => handleRemoveRow(index)} color='error'>
          <DeleteIcon />
        </IconButton>
      )
    default:
      return null
  }
}

export default RenderCell
